import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { setUpdating } from '@app/src/actions/taxFlowActions';
import HelpSupportIcon from '@app/src/assets/HelpSupportIcon';
import '@app/src/Components/TaxFlow/Question/TaxFlowListItem.scss';

const TaxFlowListItem = ({
  item,
  checked,
  handleChange,
  showRightIcon,
  handleRightIconPress,
  rightIcon,
  isWarning,
  ready = true,
  children,
  className,
  onFocus,
  setUpdating
}) => {
  return (
    <button
      className={classNames(
        'tax-flow-list-item',
        { 'tax-flow-list-item-checked': checked },
        { 'tax-flow-list-item-warning': isWarning },
        { 'tax-flow-list-item-not-ready': !ready },
        className
      )}
      onFocus={onFocus}
      onClick={(e) => {
        const checked = e.target.checked;
        setUpdating(true);
        setTimeout(async () => {
          try {
            await handleChange(item, checked);
          } finally {
            setUpdating(false);
          }
        }, 100);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          const checked = e.target.checked;
          setUpdating(true);
          setTimeout(async () => {
            try {
              await handleChange(item, checked);
            } finally {
              setUpdating(false);
            }
          }, 100);
        }
      }}
      type='button'
      aria-pressed={checked}
    >
      <div className='tax-flow-list-item-content'>
        <div className={classNames('tax-flow-list-item-text', { 'tax-flow-list-item-text-checked': checked })}>
          {children}
        </div>
      </div>
      {showRightIcon ? (
        rightIcon ? (
          rightIcon
        ) : (
          <HelpSupportIcon className='tax-flow-list-item-icon' onClick={() => handleRightIconPress(item)} />
        )
      ) : null}
    </button>
  );
};

const mapDispatchToProps = {
  setUpdating
};

const ConnectedTaxFlowListItem = connect(null, mapDispatchToProps)(TaxFlowListItem);

export default ConnectedTaxFlowListItem;
