import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetOrCreateTaxProfileQuery from '@app/src/Components/TaxProfile/hooks/useGetOrCreateTaxProfileQuery';
import useTaxEstimate from '@app/src/Components/TaxProfile/hooks/useTaxEstimate';
import useTaxEstimateDetails from '@app/src/Components/TaxProfile/hooks/useTaxEstimateDetails';
import useTaxProfileForm from '@app/src/Components/TaxProfile/hooks/useTaxProfileForm';
import { useGetWorkInfoQuery } from '@app/src/api/profileApi';
import { expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';
import { getJobCategoryList } from '@app/src/services/contentfulService';

export const useGetTaxProfileData = () => {
  const getWorkInfoQueryResults = useGetWorkInfoQuery();
  const getTaxProfileQueryResults = useGetOrCreateTaxProfileQuery();

  const queryResults = useMemo(
    () => [getWorkInfoQueryResults, getTaxProfileQueryResults],
    [getTaxProfileQueryResults, getWorkInfoQueryResults]
  );

  const data = useMemo(() => {
    return {
      income_type: getWorkInfoQueryResults?.data?.income_type,
      ...getTaxProfileQueryResults.data
    };
  }, [getTaxProfileQueryResults.data, getWorkInfoQueryResults.data?.income_type]);

  const isLoading = useMemo(() => queryResults.some((result) => result.isLoading || result.isError), [queryResults]);

  return {
    data,
    queryResults,
    isLoading
  };
};

const useTaxProfile = ({ year: yearOverride } = {}) => {
  const dispatch = useDispatch();
  const expensesYear = useSelector(expensesYearSelector);

  const year = yearOverride ?? expensesYear;

  const taxEstimateResults = useTaxEstimate(year);
  const { data: values, isLoading, queryResults } = useGetTaxProfileData();

  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (queryResults.some((result) => result.isError)) {
      setShowForm(true);
    }
  }, [queryResults]);

  useEffect(() => {
    dispatch(getJobCategoryList());
  }, [dispatch]);

  const formProps = useTaxProfileForm({
    year,
    values
  });

  const detailsProps = useTaxEstimateDetails({
    year,
    setShowForm,
    taxEstimateResults
  });

  return {
    isLoading,
    showForm,
    formProps,
    detailsProps
  };
};

export default useTaxProfile;
