import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import useEvent from 'react-use-event-hook';
import DelayedLoader from '@app/src/Components/Common/DelayedLoader/DelayedLoader';
import SettingsContainer from '@app/src/Components/Settings/SettingsContainer';
import TaxFlowHead from '@app/src/Components/TaxFlow/Common/TaxFlowHead';
import TaxFlowResponseText from '@app/src/Components/TaxFlow/Common/TaxFlowResponseText';
import TaxFlowFormItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormItem';
import TaxFlowFormPercentageSplit from '@app/src/Components/TaxFlow/Form/TaxFlowFormPercentageSplit';
import TaxFlow1099BItem from '@app/src/Components/TaxFlow/Question/TaxFlow1099BItem';
import TaxFlow1099KExpenses from '@app/src/Components/TaxFlow/Question/TaxFlow1099KExpenses';
import TaxFlowAddMoreItem from '@app/src/Components/TaxFlow/Question/TaxFlowAddMoreItem';
import TaxFlowBusinessCategoryItem from '@app/src/Components/TaxFlow/Question/TaxFlowBusinessCategoryItem';
import TaxFlowCalendarItem from '@app/src/Components/TaxFlow/Question/TaxFlowCalendarItem';
import TaxFlowDateItem from '@app/src/Components/TaxFlow/Question/TaxFlowDateItem';
import TaxFlowDropdownItem from '@app/src/Components/TaxFlow/Question/TaxFlowDropdownItem';
import TaxFlowDropdownSearchItem from '@app/src/Components/TaxFlow/Question/TaxFlowDropdownSearchItem';
import TaxFlowExpensesLinkItem from '@app/src/Components/TaxFlow/Question/TaxFlowExpensesLinkItem';
import TaxFlowFormCaptureItem from '@app/src/Components/TaxFlow/Question/TaxFlowFormCaptureItem';
import TaxFlowFormInputDropdown from '@app/src/Components/TaxFlow/Question/TaxFlowFormInputDropdown';
import TaxFlowFormUploadItem from '@app/src/Components/TaxFlow/Question/TaxFlowFormUploadItem';
import TaxFlowInfoItem from '@app/src/Components/TaxFlow/Question/TaxFlowInfoItem';
import TaxFlowMilesItem from '@app/src/Components/TaxFlow/Question/TaxFlowMilesItem';
import TaxFlowMoneyItem from '@app/src/Components/TaxFlow/Question/TaxFlowMoneyItem';
import TaxFlowMultiOptionElement from '@app/src/Components/TaxFlow/Question/TaxFlowMultiOptionElement';
import TaxFlowNumberItem from '@app/src/Components/TaxFlow/Question/TaxFlowNumberItem';
import TaxFlowOptionItem from '@app/src/Components/TaxFlow/Question/TaxFlowOptionItem';
import TaxFlowPastReturnDetails from '@app/src/Components/TaxFlow/Question/TaxFlowPastReturnDetails';
import TaxFlowPastReturns from '@app/src/Components/TaxFlow/Question/TaxFlowPastReturns';
import TaxFlowPaymentOptionsElement from '@app/src/Components/TaxFlow/Question/TaxFlowPaymentOptionsElement';
import TaxFlowPercentItem from '@app/src/Components/TaxFlow/Question/TaxFlowPercentItem';
import TaxFlowPrefillLoadingScreen from '@app/src/Components/TaxFlow/Question/TaxFlowPrefillLoadingScreen';
import TaxFlowPremiumStartElement from '@app/src/Components/TaxFlow/Question/TaxFlowPremiumStartElement';
import TaxFlowQuestionFooter from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionFooter';
import { TaxFlowQuestionHeader } from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionHeader';
import TaxFlowSliderItem from '@app/src/Components/TaxFlow/Question/TaxFlowSliderItem';
import TaxFlowSquareFootageItem from '@app/src/Components/TaxFlow/Question/TaxFlowSquareFootageItem';
import TaxFlowStandardGraph from '@app/src/Components/TaxFlow/Question/TaxFlowStandardGraph';
import TaxFlowSubmitConfirmationModal from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitConfirmationModal';
import TaxFlowSubmitEmailMyReturn from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitEmailMyReturn';
import TaxFlowSubmitSummaryElement from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitSummaryElement';
import TaxFlowSupportAccessElement from '@app/src/Components/TaxFlow/Question/TaxFlowSupportAccessElement';
import TaxFlowSwitchToDesktop from '@app/src/Components/TaxFlow/Question/TaxFlowSwitchtoDesktop';
import TaxFlowTextItem from '@app/src/Components/TaxFlow/Question/TaxFlowTextItem';
import TaxFlowWriteOffsElement from '@app/src/Components/TaxFlow/Question/TaxFlowWriteOffsElement';
import TaxFlowYearsItem from '@app/src/Components/TaxFlow/Question/TaxFlowYearsItem';
import { useGetBulkUploadPillsQuery, useGetCurrentQuestionnaireQuestionQuery } from '@app/src/api/taxDataApi';
import {
  CATEGORY_TYPE_TEXT,
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_CALENDAR,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_TAXFLOW_FORM,
  CATEGORY_TYPE_MONEY,
  CATEGORY_TYPE_TAXFLOW_FORM_DATE,
  CATEGORY_TYPE_TAXFLOW_FORM_SLIDER,
  CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN,
  CATEGORY_TYPE_TAXFLOW_FORM_NUMBER,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_MILES,
  CATEGORY_TYPE_YEARS,
  CATEGORY_TYPE_PERCENT,
  CATEGORY_TYPE_SQUARE_FOOTAGE,
  CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD,
  CATEGORY_TYPE_DROPDOWN_SEARCH,
  CATEGORY_TYPE_TAXFLOW_INFO,
  CATEGORY_TYPE_TAXFLOW_FORM_CAPTURE,
  CATEGORY_TYPE_BUSINESS_CODE,
  CATEGORY_TYPE_TAXFLOW_FORM_INPUT_DROPDOWN,
  CATEGORY_TYPE_ADD_MORE,
  CATEGORY_TYPE_TAXFLOW_PREFILL_LOADING,
  CATEGORY_TYPE_EXPENSES_LINK
} from '@app/src/constants/constants';
import { isMobileBrowser, sentMsgToReactNative } from '@app/src/global/Helpers';
import { useIsQuestionnaireFlow } from '@app/src/hooks/useIsQuestionnaireFlow';
import { isConfirmationModalOpenSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import {
  questionWrapperSelector,
  renderWideDesktopContentSelector,
  responseTextsSelector
} from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { allCollectionTypesSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { taxAmountsSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { SLUG__CREDIT_STANDARD_RESULT } from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  SLUG__FIND_WRITE_OFFS,
  SLUG__PREMIUM_START
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import { getClarifyingQuestionHeader } from '@app/src/taxflow/sections/special/utils/specialUtils';
import { SLUG__STATE_EXPENSES, SLUG__STATE_INCOME } from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_CONFIRM_ID_INTRO
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  SLUG__CONTACT_SUPPORT,
  TAXFLOW_BASE_URL,
  ENDPOINT_ATTRIBUTE__PAST_RETURNS,
  SLUG__PAST_RETURNS,
  PATH_COMPONENT__SWITCH_TO_DESKTOP
} from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  contextLoadingSelector,
  currentAnswerSelector,
  currentCollectionIdSelector,
  currentQuestionSelector,
  isNextOrPrevButtonLoadingSelector,
  queryResultsSelector,
  updatingSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';

const TaxFlowQuestionItem = (props) => {
  const {
    currentQuestion,
    replaceStrings,
    updating,
    contextLoading,
    responseTexts,
    questionWrapper,
    currentCollectionId,
    queryResults,
    renderWideDesktopContent
  } = props;

  const year = useSelector(yearSelector);
  const { data: currentQuestionnaireQuestion } = useGetCurrentQuestionnaireQuestionQuery({ year });
  const { data: bulkUploadPills } = useGetBulkUploadPillsQuery({ year });

  const isQuestionnaireFlow = useIsQuestionnaireFlow();

  const isLoading = !currentQuestion || _.isEmpty(currentQuestion) || contextLoading;

  useLoadTimeAnalytics({
    isLoading,
    eventProps: {
      collectionType: _.get(currentQuestion, 'collectionType'),
      question: _.get(currentQuestion, 'slug'),
      isQuestionnaireFlow
    }
  });

  let learnMoreInfo = _.get(currentQuestion, 'learnMoreInfo');
  if (_.get(props, ['taxFlow', 'currentQuestion', 'slug']) === 'submit-signature') {
    const federalConsent = _.get(props, ['currentQuestion', 'learnMoreInfo', 'fields', 'description']);
    if (federalConsent) {
      const usersStates = queryResults.filter(({ slug }) => slug === 'state-return');
      const allStateConsents = _.get(props, ['taxFlow', 'currentQuestion', 'question_meta']);
      const stateConsentText = usersStates.reduce((acc, stateQuery) => {
        const stateCode = _.get(stateQuery, ['answer', 'value'], '');
        const stateConsent = _.get(allStateConsents, stateCode.toLowerCase());
        if (stateConsent) {
          acc += ` \n\n ${stateConsent.state}: ${stateConsent.text}`;
        }
        return acc;
      }, '');

      learnMoreInfo = {
        ...learnMoreInfo,
        fields: {
          ...props.currentQuestion.learnMoreInfo.fields,
          description: federalConsent + stateConsentText
        }
      };
    }
  }

  if (!currentQuestion || _.isEmpty(currentQuestion)) {
    return (
      <div className='wrapper-view'>
        <TaxFlowQuestionHeader />
        <div className='steps-content-wrapper'>
          <div className='taxflow-wrapper-view-centered'>
            <DelayedLoader />
          </div>
        </div>
      </div>
    );
  }

  if (contextLoading) {
    sentMsgToReactNative('stop_native_loader');
  }

  const title = replaceStrings(currentQuestion.title);
  const summary = replaceStrings(currentQuestion.summary);
  const isFormType = currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM;
  const isFindWriteOffs = currentQuestion.slug === SLUG__FIND_WRITE_OFFS;
  const isPastReturns = currentQuestion.slug === SLUG__PAST_RETURNS;
  const displayHeader = !isFindWriteOffs && !isPastReturns;

  const clarifyingFlowHeader = getClarifyingQuestionHeader({
    currentQuestion,
    currentCollectionId,
    currentQuestionnaireQuestion,
    bulkUploadPills
  });

  return (
    <div className='wrapper-view'>
      <TaxFlowQuestionHeader />
      <div className={classNames('steps-content-wrapper', 'steps-wrapper')}>
        {isLoading && (
          <div className='taxflow-wrapper-view-centered'>
            <DelayedLoader />
          </div>
        )}
        <div
          className={classNames('steps-content', {
            'steps-content-mobile': isMobileBrowser()
          })}
        >
          {displayHeader && (
            <TaxFlowHead
              question={isQuestionnaireFlow ? questionWrapper : currentQuestion}
              title={isQuestionnaireFlow ? clarifyingFlowHeader : title}
              summary={summary}
              examples={currentQuestion.examples}
              learnMoreInfo={learnMoreInfo}
              learnMoreTitle={currentQuestion.learnMoreTitle}
              renderWideDesktopContent={renderWideDesktopContent}
              isQuestionnaireFlow={isQuestionnaireFlow}
              loading={contextLoading}
            />
          )}

          {!isLoading && (
            <div
              className={classNames({
                'steps-body-wrapper': !renderWideDesktopContent,
                'steps-body-wrapper-large': renderWideDesktopContent
              })}
            >
              {currentQuestion.question_type === CATEGORY_TYPE_ADD_MORE ? (
                <TaxFlowAddMoreItem {...props}></TaxFlowAddMoreItem>
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_DROPDOWN_SEARCH ? (
                <TaxFlowDropdownSearchItem {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD ? (
                <TaxFlowFormUploadItem {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_CAPTURE ? (
                <TaxFlowFormCaptureItem {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_INPUT_DROPDOWN ? (
                <TaxFlowFormInputDropdown {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_PREFILL_LOADING ? (
                <TaxFlowPrefillLoadingScreen {...props} />
              ) : null}
              {currentQuestion.slug === SLUG__SUBMIT_EMAIL_INFO ? <TaxFlowSubmitEmailMyReturn {...props} /> : null}
              {currentQuestion.question_type === CATEGORY_TYPE_OPTIONS &&
              currentQuestion.question_meta &&
              currentQuestion.slug !== SLUG__SUBMIT_EMAIL_INFO ? (
                <TaxFlowOptionItem {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION ? (
                <TaxFlowMultiOptionElement {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TEXT ? <TaxFlowTextItem {...props} /> : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_NUMBER ? (
                <TaxFlowNumberItem {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN ||
              currentQuestion.question_type === CATEGORY_TYPE_STATE ? (
                <TaxFlowDropdownItem currentQuestion={currentQuestion} {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_SLIDER ? (
                <TaxFlowSliderItem {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_MONEY ? <TaxFlowMoneyItem {...props} /> : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE ? (
                <TaxFlowDateItem {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_CALENDAR ? (
                <TaxFlowCalendarItem {...props} />
              ) : null}
              {currentQuestion.question_type === CATEGORY_TYPE_BUSINESS_CODE && (
                <TaxFlowBusinessCategoryItem {...props} />
              )}
              {currentQuestion.question_type === CATEGORY_TYPE_EXPENSES_LINK && <TaxFlowExpensesLinkItem {...props} />}
              {currentQuestion.slug === SLUG__SUBMIT_DEBIT ? <TaxFlowPaymentOptionsElement {...props} /> : null}
              {currentQuestion.slug === SLUG__SUBMIT_CONFIRM_ID_INTRO ? <TaxFlowInfoItem {...props} /> : null}
              {currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION ? <TaxFlowSubmitSummaryElement {...props} /> : null}
              {currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION && props.isConfirmationModalOpen ? (
                <TaxFlowSubmitConfirmationModal {...props} />
              ) : null}
              {isFindWriteOffs ? <TaxFlowWriteOffsElement {...props} /> : null}
              {currentQuestion.slug === INCOME_SLUGS.INVEST_INFO ? (
                <TaxFlow1099BItem {...props} onNext={props.onNext} />
              ) : null}
              {currentQuestion.slug === INCOME_SLUGS.FREELANCE_1099K_EXPENSES_INFO ? (
                <TaxFlow1099KExpenses {...props} onNext={props.onNext} />
              ) : null}
              {currentQuestion.slug === SLUG__STATE_INCOME || currentQuestion.slug === SLUG__STATE_EXPENSES ? (
                <TaxFlowFormPercentageSplit {...props} />
              ) : null}
              {currentQuestion.slug === SLUG__CONTACT_SUPPORT && <TaxFlowSupportAccessElement {...props} />}
              {currentQuestion.slug === SLUG__PREMIUM_START && <TaxFlowPremiumStartElement {...props} />}
              {currentQuestion.question_type === CATEGORY_TYPE_MILES ? <TaxFlowMilesItem {...props} /> : null}
              {currentQuestion.question_type === CATEGORY_TYPE_YEARS ? <TaxFlowYearsItem {...props} /> : null}
              {currentQuestion.question_type === CATEGORY_TYPE_PERCENT ? <TaxFlowPercentItem {...props} /> : null}
              {currentQuestion.question_type === CATEGORY_TYPE_SQUARE_FOOTAGE ? (
                <TaxFlowSquareFootageItem {...props} />
              ) : null}
              {currentQuestion.slug === SLUG__CREDIT_STANDARD_RESULT && <TaxFlowStandardGraph />}
              {currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_INFO && <TaxFlowInfoItem {...props} />}
              {isFormType &&
              ![INCOME_SLUGS.INVEST_INFO, SLUG__SUBMIT_EMAIL_INFO, INCOME_SLUGS.FREELANCE_1099K_EXPENSES_INFO].includes(
                currentQuestion.slug
              ) ? (
                <TaxFlowFormItem {...props} onNext={props.onNext} />
              ) : null}
              <TaxFlowResponseText responseTexts={responseTexts} replaceStrings={replaceStrings} />
              <Route
                path={`/${TAXFLOW_BASE_URL}/${ENDPOINT_ATTRIBUTE__PAST_RETURNS}`}
                render={() => <TaxFlowPastReturns {...props} />}
                exact
              />
              <Route
                path={`/${TAXFLOW_BASE_URL}/${ENDPOINT_ATTRIBUTE__PAST_RETURNS}/:year`}
                render={() => <TaxFlowPastReturnDetails {...props} />}
                exact
              />
              <Route
                path={`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SWITCH_TO_DESKTOP}`}
                render={() => <TaxFlowSwitchToDesktop {...props} />}
                exact
              />
              <Route path={'/settings'} render={() => <SettingsContainer />} exact />
            </div>
          )}
          <TaxFlowQuestionFooter
            {...props}
            contextLoading={contextLoading}
            currentQuestion={currentQuestion}
            isUpdating={updating}
            NextButtonLabel={props.NextButtonLabel || currentQuestion.nextButtonLabel}
          />
        </div>
      </div>
    </div>
  );
};

/** track question load time + transition time between questions */
const useLoadTimeAnalytics = ({ isLoading, eventProps }) => {
  const [loadStartTime, setLoadStartTime] = useState();
  const [transitionStartTime, setTransitionStartTime] = useState(null);
  const [transitionStartEventProps, setTransitionStartEventProps] = useState(eventProps);

  const isNextOrPrevButtonLoading = useSelector(isNextOrPrevButtonLoadingSelector);

  // Track question load times
  const trackLoadTime = useEvent(() => {
    if (_.isNumber(loadStartTime)) {
      trackActivity('tax question load time', { timeMs: performance.now() - loadStartTime, ...eventProps });
    }
  });

  const trackTransitionTime = useEvent(() => {
    if (_.isNumber(transitionStartTime)) {
      trackActivity('tax question transition time', {
        timeMs: performance.now() - transitionStartTime,
        from: { ...transitionStartEventProps },
        to: { ...eventProps }
      });
    }
  });

  useEffect(() => {
    if (!isLoading) {
      trackLoadTime();
    }
    if (isLoading) {
      setLoadStartTime(performance.now());
    }
  }, [isLoading, trackLoadTime]);

  const onTransitionStart = useEvent(() => {
    setTransitionStartTime(performance.now());
    setTransitionStartEventProps(eventProps);
  });

  // Question transitions begin upon the next / prev button being disabled (user explicitly clicked one of these)
  useEffect(() => {
    if (isNextOrPrevButtonLoading) {
      onTransitionStart();
    }
  }, [isNextOrPrevButtonLoading, onTransitionStart]);

  // Once the next / prev button is re-enabled and page loading has completed, mark the transition as complete
  useEffect(() => {
    if (!isNextOrPrevButtonLoading && !isLoading) {
      trackTransitionTime();
      setTransitionStartTime(null);
    }
  }, [isNextOrPrevButtonLoading, isLoading, trackTransitionTime]);
};

const mapStateToProps = (state, props) => ({
  taxFlow: state.taxFlow,
  taxAmounts: taxAmountsSelector(state),
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: currentAnswerSelector(state),
  queryResults: queryResultsSelector(state),
  replaceStrings: props.replaceStrings || _.identity,
  isConfirmationModalOpen: isConfirmationModalOpenSelector(state),
  updating: updatingSelector(state),
  contextLoading: contextLoadingSelector(state),
  responseTexts: responseTextsSelector(state),
  questionWrapper: questionWrapperSelector(state),
  currentCollectionId: currentCollectionIdSelector(state),
  allCollectionTypes: allCollectionTypesSelector(state),
  renderWideDesktopContent: renderWideDesktopContentSelector(state)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowQuestionItem = connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxFlowQuestionItem));

export default ConnectedTaxFlowQuestionItem;
