import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';
import PaymentModal from '@app/src/Components/Subscription/PaymentModal/PaymentModal';
import TaxFlowBlockingModal from '@app/src/Components/TaxFlow/Common/TaxFlowBlockingModal';
import TaxFlowCard from '@app/src/Components/TaxFlow/Common/TaxFlowCard';
import TaxFlowInfoModalWindow from '@app/src/Components/TaxFlow/Common/TaxFlowInfoModalWindow';
import { useAllYearUIStates } from '@app/src/hooks/useAllYearUIStates';
import { useNavTaxHomeOnYearChange } from '@app/src/hooks/useNavTaxHomeOnYearChange';
import { useReloadOnStuckQuery } from '@app/src/hooks/useReloadOnStuckQuery';
import { useReportUserTaxStage } from '@app/src/hooks/useReportUserTaxStage';
import { useSocket } from '@app/src/hooks/useSocket';
import { useUpdatePageOnUIStageChange } from '@app/src/hooks/useUpdatePageOnUIStageChange';
import { isPaymentModalOpenSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { TaxFlowHome } from '@app/src/taxflow/main/components/TaxFlowHome';
import TaxFlowQuestionContent from '@app/src/taxflow/main/components/TaxFlowQuestionContent';
import TaxFlowNavigationPanel from '@app/src/taxflow/navigation/components/TaxFlowNavigationPanel';
import TaxFlowSubscriptionModal from '@app/src/taxflow/navigation/components/TaxFlowSubscriptionModal';

const TaxFlowContainer = () => {
  const location = useLocation();
  const history = useHistory();
  useUpdatePageOnUIStageChange({ uiStagePollingIntervalMs: 15000 });
  useNavTaxHomeOnYearChange();
  useAllYearUIStates();
  useSocket();
  useReportUserTaxStage();
  useReloadOnStuckQuery();
  const isPaymentModalOpen = useSelector(isPaymentModalOpenSelector);
  return (
    <StripeProvider>
      <div className='taxflow-wrapper'>
        <TaxFlowNavigationPanel />
        <div className='taxflow-content'>
          <div className='taxflow-content-center'>
            <TaxFlowCard location={location}>
              <div className={'taxflow-box-wrap'}>
                <div className='taxflow-box-wrap-left-spacer' />
                <Switch>
                  <Route path='/tax-filing/home' component={TaxFlowHome} />
                  <Route component={TaxFlowQuestionContent} />
                </Switch>
                <div className='taxflow-box-wrap-right-spacer' />
              </div>
            </TaxFlowCard>
            <TaxFlowInfoModalWindow />
            {isPaymentModalOpen && <PaymentModal history={history} />}
          </div>
          <TaxFlowSubscriptionModal />
        </div>
      </div>
      <TaxFlowBlockingModal />
    </StripeProvider>
  );
};

export default TaxFlowContainer;
