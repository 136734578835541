import React, { useEffect } from 'react';
import _ from 'lodash';
import Icon from '@mui/material/Icon';
import classNames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import {
  setOrigin,
  setOverrides,
  setPlansShown,
  setSubscriptionType,
  setSubscriptionTypes
} from '@app/src/actions/pricingActions';
import { setCurrentAnswer, setSubscriptionModalShow, updateCurrentAnswer } from '@app/src/actions/taxFlowActions';
import { allTaxYearExpensesSelector } from '@app/src/api/expensesApi';
import { useGetPaidQuery } from '@app/src/api/plaidApi';
import { useGetReviewPillsQuery, useGetTaxDataQuery } from '@app/src/api/taxDataApi';
import MailIcon from '@app/src/assets/mail.svg?react';
import { SUBSCRIPTION_PLANS } from '@app/src/constants/constants';
import { PRICING_ORIGINS } from '@app/src/constants/pricingConstants';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { useShouldShowPremiumPaywall } from '@app/src/hooks/useShouldShowPremiumPaywall';
import { subscriptionInfoSelector, subscriptionTypesSelector } from '@app/src/selectors/pricingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { freeTaxFilingSelector } from '@app/src/selectors/workSelectors';
import { getSubscriptionInfo, getSubscriptionTypes } from '@app/src/services/pricingService';
import { extractDrakePdf } from '@app/src/services/taxFlowService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { INCOME_COLLECTION_TYPES } from '@app/src/taxflow/sections/income/incomeConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitEmailMyReturn.scss';

const TaxFlowSubmitEmailMyReturn = (props) => {
  const dispatch = useDispatch();

  const {
    user,
    currentQuestion,
    currentAnswer,
    resultLoading,
    isChildQuestion,
    extractDrakePdf,
    setSubscriptionModalShow,
    setOrigin,
    year
  } = props;

  const freeFiling = useSelector(freeTaxFilingSelector);
  const expenses = useSelector(allTaxYearExpensesSelector);
  const subscriptionInfo = useSelector(subscriptionInfoSelector);
  const subscriptionTypes = useSelector(subscriptionTypesSelector);

  const { data: userHasPaid, isLoading: isUserHasPaidLoading } = useGetPaidQuery();
  const { data: reviewPills, isLoading: areReviewPillsLoading } = useGetReviewPillsQuery({ year });
  const { data: taxData, isLoading: taxDataLoading } = useGetTaxDataQuery({
    year
  });

  const shouldShowPremiumPaywall = useShouldShowPremiumPaywall({ subscriptionInfo, reviewPills });

  const onClick = async ({ value }) => {
    dispatch(setCurrentAnswer({ value }));
    if (value === '1') {
      await extractDrakePdf(year);
    }
  };

  useEffect(() => {
    // TODO: Remove these once we've converted to using RTK query
    dispatch(getSubscriptionInfo()), dispatch(getSubscriptionTypes());
  }, [dispatch]);

  useEffect(() => {
    const userNeedsToPay = !freeFiling && !userHasPaid && !isUserHasPaidLoading;
    const has1099Income =
      !taxDataLoading && !_.isNil(taxData.find((item) => item.coll_type === INCOME_COLLECTION_TYPES.FREELANCE));

    if (shouldShowPremiumPaywall) {
      // Show paywall if user has premium pills and is not currently a premium subscriber
      dispatch(setOrigin({ origin: PRICING_ORIGINS.TAX_FILING_PREMIUM_PAYWALL }));
      dispatch(
        setOverrides({
          subscriptionType: {
            title: 'Filing your tax return requires a Premium subscription',
            subtitle: null,
            CTA: {
              primary: {
                text: 'Continue'
              }
            },
            defaultSelected: SUBSCRIPTION_PLANS.PREMIUM,
            refundCopy: false
          },
          pricingMethod: {
            title: 'Choose payment method',
            subtitle: "You'll be charged {price} / {period}. Cancel anytime."
          }
        })
      );
      if (isReactNative()) {
        sentMsgToReactNative('open_subscribe_modal', { origin: PRICING_ORIGINS.TAX_FILING_PREMIUM_PAYWALL });
      } else {
        dispatch(setPlansShown({ plansShown: [SUBSCRIPTION_PLANS.PREMIUM] }));
        dispatch(setSubscriptionModalShow(true));
      }
      return;
    } else if (userNeedsToPay) {
      // Show hard paywall if user needs to pay, but doesn't have any premium pills
      if (isReactNative()) {
        sentMsgToReactNative('open_subscribe_modal', {
          origin: PRICING_ORIGINS.TAX_FILING_HARD_PAYWALL,
          taxData
        });
        return;
      } else {
        const plansShown = has1099Income ? [SUBSCRIPTION_PLANS.ANNUAL] : [SUBSCRIPTION_PLANS.FILING_ONLY];
        const subscriptionType = has1099Income ? SUBSCRIPTION_PLANS.ANNUAL : SUBSCRIPTION_PLANS.FILING_ONLY;
        dispatch(setSubscriptionType(subscriptionType));
        dispatch(
          setOverrides({
            subscriptionType: {
              title: 'Start annual plan to file',
              subtitle: null,
              CTA: {
                primary: {
                  text: 'Continue'
                }
              },
              defaultSelected: 'annual',
              refundCopy: false
            },
            pricingMethod: {
              title: 'Choose payment method',
              subtitle: "You'll be charged {price} / {period}. Cancel anytime.",
              refundCopy: false,
              CTA: {
                primary: {
                  text: 'Pay now'
                }
              }
            }
          })
        );
        dispatch(setOrigin({ origin: PRICING_ORIGINS.TAX_FILING_HARD_PAYWALL }));
        dispatch(
          setPlansShown({
            plansShown
          })
        );
        setSubscriptionModalShow(true);
        if (!has1099Income && !_.isEmpty(subscriptionTypes)) {
          const newSubscriptionTypes = {
            ...subscriptionTypes,
            [SUBSCRIPTION_PLANS.FILING_ONLY]: {
              ...subscriptionTypes[SUBSCRIPTION_PLANS.FILING_ONLY],
              warning: null
            }
          };
          if (!_.isEqual(subscriptionTypes, newSubscriptionTypes)) {
            dispatch(setSubscriptionTypes({ subscriptionTypes: newSubscriptionTypes }));
          }
        }
        return;
      }
    }
  }, [
    dispatch,
    expenses,
    freeFiling,
    userHasPaid,
    setSubscriptionModalShow,
    setOrigin,
    isUserHasPaidLoading,
    shouldShowPremiumPaywall,
    taxData,
    taxDataLoading,
    subscriptionTypes
  ]);

  if (resultLoading || isUserHasPaidLoading || areReviewPillsLoading || taxDataLoading) return null;

  return (
    <>
      <div className={classNames('taxflow-submit-email-my-return', { 'steps-body-wide': !isChildQuestion })}>
        <div style={{ paddingBottom: '16px' }}>
          <TaxFlowPill
            text={user.email}
            icon={
              <Icon className='taxflow-chip-icon'>
                <MailIcon width={16} height={16} />
              </Icon>
            }
          />
        </div>
        <div className='checkbox-list'>
          {currentQuestion.question_meta.map((item) => (
            <TaxFlowListItem
              item={item}
              handleChange={onClick}
              checked={currentAnswer && !_.isNil(currentAnswer.value) && `${item.value}` === `${currentAnswer.value}`}
              rightIcon={<TaxFlowInfoButton currentQuestion={currentQuestion} item={item} />}
              key={item.value}
            >
              <span>{props.replaceStrings(item.text)}</span>
            </TaxFlowListItem>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: state.taxFlow.currentAnswer,
  year: yearSelector(state)
});

const mapDispatchToProps = {
  updateCurrentAnswer,
  extractDrakePdf,
  setSubscriptionModalShow,
  setOrigin
};

const ConnectedTaxFlowSubmitEmailMyReturn = connect(mapStateToProps, mapDispatchToProps)(TaxFlowSubmitEmailMyReturn);

export default ConnectedTaxFlowSubmitEmailMyReturn;
