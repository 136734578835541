import React, { useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import ExpenseReviewSidebarRules from '@app/src/Components/ExpenseReview/ExpenseReviewSidebarRules';
import ExpenseReviewSidebarSavingsInfo from '@app/src/Components/ExpenseReview/ExpenseReviewSidebarSavingsInfo';
import TaxProfile from '@app/src/Components/TaxProfile/TaxProfile';
import TaxProfileForm from '@app/src/Components/TaxProfile/TaxProfileForm';
import { setAssistantOpen } from '@app/src/actions/assistantActions';
import { setSidebarComponent } from '@app/src/actions/expenseReviewActions';
import CloseIcon from '@app/src/assets/close.svg?react';
import { sidebarOptionsSelector, sidebarComponentSelector } from '@app/src/selectors/expenseReviewSelectors';
import '@app/src/Components/ExpenseReview/ExpenseReviewSidebar.scss';

const ExpenseReviewSidebar = ({ component, componentOptions, setSidebarComponent, setAssistantOpen }) => {
  const getContent = () => {
    switch (component) {
      case 'TaxProfileForm':
        return <TaxProfileForm {...componentOptions} />;
      case 'TaxProfile':
        return <TaxProfile />;
      case 'SavingsInfo':
        return <ExpenseReviewSidebarSavingsInfo />;
      case 'Rules':
        return <ExpenseReviewSidebarRules />;
      default:
        return <ExpenseReviewSidebarSavingsInfo />;
    }
  };

  useEffect(() => {
    if (component) {
      setAssistantOpen(false);
    }
  }, [component, setAssistantOpen]);

  return (
    <Drawer
      anchor='right'
      open={Boolean(component)}
      onClose={() => setSidebarComponent(null)}
      variant='persistent'
      className='base-sidebar-panel'
      ModalProps={{
        keepMounted: true
      }}
      PaperProps={{
        style: {
          padding: '16px',
          overflowY: component === 'TaxProfileForm' || component === 'TaxProfile' ? 'scroll' : undefined
        }
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
        onClick={() => setSidebarComponent(null)}
      >
        <CloseIcon />
      </IconButton>
      <div className='base-sidebar-panel-content'>{getContent()}</div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  component: sidebarComponentSelector(state),
  componentOptions: sidebarOptionsSelector(state)
});

const mapDispatchToProps = {
  setSidebarComponent,
  setAssistantOpen
};

const ConnectedExpenseReviewSidebar = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewSidebar);

export default ConnectedExpenseReviewSidebar;
