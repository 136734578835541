import _ from 'lodash';
import { setOnboardingExperiments } from '@app/src/actions/onboardingActions';
import { FEATURE_FLAG__GET_STARTED, ONBOARDING_EXPERIMENTS_KEY } from '@app/src/constants/onboardingConstants';
import { trackActivity } from '@app/src/services/analyticsService';

export const parseExperimentProperties = (experiments) => {
  const experimentObj = _.fromPairs(
    _.flatMap(experiments, (experiment) => {
      const match = /^(.*)_(test[^_]*|control)$/.exec(experiment);
      if (match) {
        return [[match[1], match[2]]];
      }

      return [];
    })
  );

  return {
    onboarding_test_groups: experiments,
    experiments: experimentObj
  };
};

export const parseSignupExperiments = (experiments) => {
  const availableSignupExperiments = [];
  return _.pick(experiments, availableSignupExperiments);
};

export const initOnboardingExperimentGroups =
  ({ params }) =>
  async (dispatch) => {
    // leave as empty array if no active experiments
    const activeFeatures = [{ name: FEATURE_FLAG__GET_STARTED, percentAssigned: 50 }];

    let userExperiments;
    let origin;

    // URL Override forces experiment for testing purposes.
    if (params && params.experiments) {
      const { experiments } = params;
      userExperiments = experiments.split(',').map((exp) => exp);
      origin = 'url';
    } else {
      if (!_.isNil(localStorage.getItem(ONBOARDING_EXPERIMENTS_KEY))) {
        const previouslyAssignedExperiments = JSON.parse(localStorage.getItem(ONBOARDING_EXPERIMENTS_KEY));
        userExperiments = previouslyAssignedExperiments;
        origin = 'restored';
      } else {
        userExperiments = [];

        // Assign user to active experiments based on percentage
        userExperiments = activeFeatures.map((feature) => {
          const randomNumber = Math.random() * 100;
          const group = randomNumber < feature.percentAssigned ? '_test' : '_control';
          return feature.name + group;
        });
        origin = 'new';
      }
    }

    dispatch(setOnboardingExperiments(userExperiments));
    localStorage.setItem(ONBOARDING_EXPERIMENTS_KEY, JSON.stringify(userExperiments));
    window.analytics.identify({ preSignupExperiments: userExperiments });

    trackActivity('pre-signup experiment assigned', {
      preSignupExperiments: userExperiments,
      origin
    });
  };
