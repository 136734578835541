import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { BankLinkOptions } from '@app/src/Components/BankLinkOptions/BankLinkOptions';
import ExpenseReviewCategoryCounts from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCounts';
import TaxFlowEditExpensesNavigator from '@app/src/Components/TaxFlow/Common/TaxFlowEditExpensesNavigator';
import { useHasTransactions } from '@app/src/hooks/useHasDeductions';
import { useTransactionsSyncInProgress } from '@app/src/hooks/useTransactionsSyncInProgress';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const TaxFlowDeductionsSummary = ({ setLoading = _.noop }) => {
  const year = useSelector(yearSelector);

  const { syncInProgress: transactionsSyncInProgress, isLoading: transactionsSyncInProgressLoading } =
    useTransactionsSyncInProgress({ year });
  const { hasTransactions, isLoading: hasTransactionsLoading } = useHasTransactions({ year });

  const [expensesNavigatorActive, setExpensesNavigatorActive] = useState(false);

  const isLoading = transactionsSyncInProgressLoading || hasTransactionsLoading;

  useEffect(() => setLoading(isLoading), [isLoading, setLoading]);

  const navigateExpenses = () => {
    setExpensesNavigatorActive(true);
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      {hasTransactions && !transactionsSyncInProgress ? (
        <>
          <ExpenseReviewCategoryCounts expensesYear={year} onClick={navigateExpenses} />
          <TaxFlowEditExpensesNavigator active={expensesNavigatorActive} setActive={setExpensesNavigatorActive} />
        </>
      ) : (
        <BankLinkOptions
          origin='taxFlowDeductionsSummary'
          syncBehavior='disableWhileSyncing'
          subTitle={
            transactionsSyncInProgress
              ? 'This may take a minute'
              : 'Link your financial accounts to automatically scan for deductions'
          }
        />
      )}
    </div>
  );
};
