import React, { Component } from 'react';
import _ from 'lodash';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxValidationInput from '@app/src/Components/TaxValidation/TaxValidationInput';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';

class TaxFlowFormTextItem extends Component {
  onChange = (value) => {
    this.props.onChange({ slug: this.props.question.slug, value, endpoint_attr: this.props.question.endpoint_attr });
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ endpoint_attr: this.props.question.endpoint_attr });
    }
  };

  render() {
    const { question, answer } = this.props;

    return (
      <div>
        <div className='form-group'>
          <TaxValidationLabel
            question={question}
            answer={answer}
            title={this.props.replaceStrings(question.title)}
            htmlFor={question.slug}
          />
          <div className='tax-flow-input-wrap'>
            <TaxValidationInput
              question={question}
              answer={answer}
              type={question.question_meta.type}
              name={question.slug}
              id={question.slug}
              placeholder={question.question_meta.default}
              required={question.required}
              onFocus={this.onFocus}
              onChange={(e) => this.onChange(e.target.value)}
              value={answer && !_.isNil(answer.value) ? answer.value : ''}
            />
            <TaxFlowInfoButton currentQuestion={question} />
          </div>
          <TaxValidationWarnings question={question} answer={answer} />
        </div>
      </div>
    );
  }
}

export default TaxFlowFormTextItem;
