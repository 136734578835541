/**
 * @desc Reducers Action List
 */

// Auth
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_AUTH_RES_ERROR = 'GET_AUTH_RES_ERROR';
export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_EMAIL_UNIQUE = 'SET_EMAIL_UNIQUE';
export const SET_PHONE_UNIQUE = 'SET_PHONE_UNIQUE';

// Socket
export const CONNECT_SOCKET = 'CONNECT_SOCKET';
export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET';
export const SET_FORM_UPLOAD_CLOUD_STATUS = 'SET_FORM_UPLOAD_CLOUD_STATUS';
export const CLEAR_FORM_UPLOAD_ALERT = 'CLEAR_FORM_UPLOAD_ALERT';

// Bank
export const GET_BANK_LIST = 'GET_BANK_LIST';
export const LOAD_BANK_DETAILS = 'LOAD_BANK_DETAILS';
export const CLEAR_BANK_LIST = 'CLEAR_BANK_LIST';
export const SET_CHARGEABLE_ACCOUNT = 'SET_CHARGEABLE_ACCOUNT';
export const SET_BANK_LIST_UPDATE_STATUS = 'SET_BANK_LIST_UPDATE_STATUS';
export const UPDATE_BANK_RES_MSG = 'UPDATE_BANK_RES_MSG';
export const SET_LINK_TOKEN = 'SET_LINK_TOKEN';
export const SET_BANK_INFO = 'SET_BANK_INFO';
export const SET_BANK_INFO_LOADING = 'SET_BANK_INFO_LOADING';
export const SET_BANK_INFO_ERROR_MSG = 'SET_BANK_INFO_ERROR_MSG';
export const SET_BANK_LINK_FAILURE = 'SET_BANK_LINK_FAILURE';
export const SET_BANK_LINK_SKIPPED = 'SET_BANK_LINK_SKIPPED';
export const SET_BANK_LINK_CHASE = 'SET_BANK_LINK_CHASE';
export const FORCE_PLAID_REFRESH = 'FORCE_PLAID_REFRESH';
export const SET_BANK_LIST_PAGE_LOADING = 'SET_BANK_LIST_PAGE_LOADING';

// Work
export const GET_WORK_INFO = 'GET_WORK_INFO';
export const LOAD_WORK_INFO = 'LOAD_WORK_INFO';
export const UPDATE_WORK_RES_MSG = 'UPDATE_WORK_RES_MSG';
export const UPDATE_WORK_CATEGORIES = 'UPDATE_WORK_CATEGORIES';
export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const SET_BUILD = 'SET_BUILD';

//Onboarding
export const SET_ONBOARDING_JOB_CATEGORY_LIST = 'SET_ONBOARDING_JOB_CATEGORY_LIST';
export const SET_ONBOARDING_ERROR = 'SET_ONBOARDING_ERROR';
export const SET_ONBOARDING_LOADING = 'SET_ONBOARDING_LOADING';
export const SET_ONBOARDING_UPDATING = 'SET_ONBOARDING_UPDATING';
export const SET_PARTNER_PRICING = 'SET_PARTNER_PRICING';
export const SET_ONBOARDING_INITIAL_LOAD = 'SET_ONBOARDING_INITIAL_LOAD';
export const SET_ONBOARDING_EXPERIMENTS = 'SET_ONBOARDING_EXPERIMENTS';
export const SET_ONBOARDING_MARKETING_CONTENT = 'SET_ONBOARDING_MARKETING_CONTENT';
export const SET_ONBOARDING_TYPE = 'SET_ONBOARDING_TYPE';
export const SET_ONBOARDING_NORMALIZED_QUESTIONS = 'SET_ONBOARDING_NORMALIZED_QUESTIONS';
export const SET_ONBOARDING_CURRENT_QUESTION = 'SET_ONBOARDING_CURRENT_QUESTION';
export const SET_ONBOARDING_CURRENT_ANSWER = 'SET_ONBOARDING_CURRENT_ANSWER';
export const SET_ONBOARDING_CURRENT_CONTEXT = 'SET_ONBOARDING_CURRENT_CONTEXT';

//Tax Filing
export const SET_TAXFILING_LOADER = 'SET_TAXFILING_LOADER';
export const SET_TAXFILING_ERROR = 'SET_TAXFILING_ERROR';
export const SET_TAXFILING_IS_WEBVIEW_FOCUSED = 'SET_TAXFILING_IS_WEBVIEW_FOCUSED';
export const SET_TAXFILING_CURRENT_ANSWER = 'SET_TAXFILING_CURRENT_ANSWER';
export const SET_TAXFILING_CURRENT_CONTEXT = 'SET_TAXFILING_CURRENT_CONTEXT';
export const SET_TAXFILING_CURRENT_CONTEXT_ONLY = 'SET_TAXFILING_CURRENT_CONTEXT_ONLY';
export const SET_TAXFILING_CONTEXT_LOADING = 'SET_TAXFILING_CONTEXT_LOADING';
export const SET_TAXFILING_RESULT_LOADING = 'SET_TAXFILING_RESULT_LOADING';
export const SET_TAXFILING_CURRENT_QUESTION = 'SET_TAXFILING_CURRENT_QUESTION';
export const RESET_TAXFILING_CURRENT_QUESTION = 'RESET_TAXFILING_CURRENT_QUESTION';
export const UPDATE_TAXFILING_CURRENT_ANSWER = 'UPDATE_TAXFILING_CURRENT_ANSWER';
export const UPDATE_TAXFILING_CURRENT_ANSWER_BULK = 'UPDATE_TAXFILING_CURRENT_ANSWER_BULK';
export const SET_TAXFILING_UPDATING = 'SET_TAXFILING_UPDATING';
export const UPDATE_TAXFILING_CACHE = 'UPDATE_TAXFILING_CACHE';
export const REPLACE_TAXFILING_QUESTIONS = 'REPLACE_TAXFILING_QUESTIONS';
export const REPLACE_TAXFILING_INFO_ITEMS = 'REPLACE_TAXFILING_INFO_ITEMS';
export const MERGE_TAXFILING_CURRENT_CONTEXT = 'MERGE_TAXFILING_CURRENT_CONTEXT';
export const MERGE_TAXFILING_CURRENT_QUESTION = 'MERGE_TAXFILING_CURRENT_QUESTION';
export const SET_TAXFILING_IS_CONFIRMATION_MODAL_OPEN = 'SET_TAXFILING_IS_CONFIRMATION_MODAL_OPEN';
export const SET_TAXFILING_IS_PAYMENT_MODAL_OPEN = 'SET_TAXFILING_IS_PAYMENT_MODAL_OPEN';
export const SET_TAXFILING_NAVIGATION_ITEMS = 'SET_TAXFILING_NAVIGATION_ITEMS';
export const SET_TAXFILING_SELF_UNLOCK_MODAL_OPEN = 'SET_TAXFILING_SELF_UNLOCK_MODAL_OPEN';
export const SET_TAXFILING_DROPDOWN_WARNINGS = 'SET_TAXFILING_DROPDOWN_WARNINGS';
export const SET_TAXFILING_INDUSTRIES = 'SET_TAXFILING_INDUSTRIES';
export const SET_TAXFILING_BUSINESS_CODES = 'SET_TAXFILING_BUSINESS_CODES';
export const SET_TAXFILING_BULK_UPLOAD_ITEMS = 'SET_TAXFILING_BULK_UPLOAD_ITEMS';
export const SET_TAXFILING_BULK_UPLOAD_PILLS = 'SET_TAXFILING_BULK_UPLOAD_PILLS';
export const SET_TAXFILING_RATING = 'SET_TAXFILING_RATING';
export const SET_TAXFILING_HOVER_RATING = 'SET_TAXFILING_HOVER_RATING';
export const SET_TAXFILING_NAV_SECTION_PILLS = 'SET_TAXFILING_NAV_SECTION_PILLS';
export const SET_TAXFILING_SUMMARY_ITEMS = 'SET_TAXFILING_SUMMARY_ITEMS';
export const SET_TAXFILING_ALL_QUESTIONS = 'SET_TAXFILING_ALL_QUESTIONS';
export const SET_TAXFILING_QUERY_RESULTS = 'SET_TAXFILING_QUERY_RESULTS';
export const SET_TAXFILING_COGS_SUMMARY = 'SET_TAXFILING_COGS_SUMMARY';
export const SET_TAXFILING_DEDUCTIONS = 'SET_TAXFILING_DEDUCTIONS';
export const SET_TAXFILING_CAR_PRIOR_DEPRECIATION = 'SET_TAXFILING_CAR_PRIOR_DEPRECIATION';
export const SET_TAXFILING_HOME_PRIOR_DEPRECIATION = 'SET_TAXFILING_HOME_PRIOR_DEPRECIATION';
export const SET_TAXFILING_FORM_UPLOAD_ATTEMPTS = 'SET_TAXFILING_FORM_UPLOAD_ATTEMPTS';
export const SET_TAXFILING_UPLOAD_ERROR = 'SET_TAXFILING_UPLOAD_ERROR';
export const SET_TAXFILING_APP_OS = 'SET_TAXFILING_APP_OS';
export const SET_TAXFILING_CURRENT_COLLECTION_ID = 'SET_TAXFILING_CURRENT_COLLECTION_ID';
export const SET_TAXFILING_CURRENT_TAX_STATE = 'SET_TAXFILING_CURRENT_TAX_STATE';
export const SET_TAXFILING_IRS_PAYMENT_ACCOUNT = 'SET_TAXFILING_IRS_PAYMENT_ACCOUNT';
export const SET_TAXFILING_TAX_AMOUNTS = 'SET_TAXFILING_TAX_AMOUNTS';
export const SET_TAXFILING_SUBMIT_TIMESTAMP = 'SET_TAXFILING_SUBMIT_TIMESTAMP';
export const SET_TAXFILING_IS_SUBMIT_DONE = 'SET_TAXFILING_IS_SUBMIT_DONE';
export const SET_TAXFILING_IS_NAVIGATION_PANEL_OPEN = 'SET_TAXFILING_IS_NAVIGATION_PANEL_OPEN';
export const SET_TAXFILING_SUPPORT_ACCESS_ORIGIN = 'SET_TAXFILING_SUPPORT_ACCESS_ORIGIN';
export const SET_TAXFILING_NAVIGATION_SECTIONS = 'SET_TAXFILING_NAVIGATION_SECTIONS';
export const SET_TAXFILING_CURRENT_UPLOADS = 'SET_TAXFILING_CURRENT_UPLOADS';
export const SET_TAXFILING_FORM_UPLOAD_KEYED_FILES = 'SET_TAXFILING_FORM_UPLOAD_KEYED_FILES';
export const SET_TAXFILING_FORM_UPLOAD_STATUS = 'SET_TAXFILING_FORM_UPLOAD_STATUS';
export const SET_TAXFILING_DROPZONE_LOADING = 'SET_TAXFILING_DROPZONE_LOADING';
export const SET_TAXFILING_DROPZONE_FILES = 'SET_TAXFILING_DROPZONE_FILES';
export const SET_TAXFILING_DROPZONE_FILES_READY_FOR_UPLOAD = 'SET_TAXFILING_DROPZONE_FILES_READY_FOR_UPLOAD';
export const SET_TAXFILING_DROPZONE_FILES_SAME_FORM = 'SET_TAXFILING_DROPZONE_FILES_SAME_FORM';
export const SET_TAXFILING_BUSINESS_CODE = 'SET_TAXFILING_BUSINESS_CODE';
export const SET_TAXFILING_CURRENT_GET_QUESTION_ANSWER_LOADING_SLUG =
  'SET_TAXFILING_CURRENT_GET_QUESTION_ANSWER_LOADING_SLUG';
export const SET_IS_NEXT_BUTTON_LOADING = 'SET_IS_NEXT_BUTTON_LOADING';
export const SET_TAXFILING_BLOCKING_MODAL_PROPS = 'SET_TAXFILING_BLOCKING_MODAL_PROPS';

// bulk upload clarifying questions flow
export const SET_TAXFILING_IS_QUESTIONNAIRE_FLOW = 'SET_TAXFILING_IS_QUESTIONNAIRE_FLOW';
export const SET_TAXFILING_QUESTION_WRAPPER = 'SET_TAXFILING_QUESTION_WRAPPER';

export const SET_TAXFILING_ADD_MORE_ITEMS = 'SET_TAXFILING_ADD_MORE_ITEMS';

//Tax Filing Modals
export const SET_TAXFILING_INFO_MODAL_SHOW = 'SET_TAXFILING_INFO_MODAL_SHOW';
export const SET_TAXFILING_INFO_MODAL_ITEM = 'SET_TAXFILING_INFO_MODAL_ITEM';
export const SET_TAXFILING_COLLECTION_INFO_MODAL_SHOW = 'SET_TAXFILING_COLLECTION_INFO_MODAL_SHOW';
export const SET_TAXFILING_COLLECTION_MODAL_TITLE = 'SET_TAXFILING_COLLECTION_MODAL_TITLE';
export const SET_TAXFILING_COLLECTION_MODAL_DESCRIPTION = 'SET_TAXFILING_COLLECTION_MODAL_DESCRIPTION';
export const SET_TAXFILING_DELETE_MODAL_SHOW = 'SET_TAXFILING_DELETE_MODAL_SHOW';
export const SET_TAXFILING_DELETE_MODAL_SLUG = 'SET_TAXFILING_DELETE_MODAL_SLUG ';
export const SET_TAXFILING_DELETE_MODAL_COLLECTION_ID = 'SET_TAXFILING_DELETE_MODAL_COLLECTION_ID';
export const SET_TAXFILING_DELETE_MODAL_COLLECTION_TYPE = 'SET_TAXFILING_DELETE_MODAL_COLLECTION_TYPE';
export const SET_DESKTOP_SELL_MODAL_SHOW = 'SET_DESKTOP_SELL_MODAL_SHOW';
export const SET_FEEDBACK_MODAL_SHOW = 'SET_FEEDBACK_MODAL_SHOW';
export const SET_SUBSCRIPTION_MODAL_SHOW = 'SET_SUBSCRIPTION_MODAL_SHOW';

// Tax Filing Assistant
export const SET_ASSISTANT_MESSAGES = 'SET_ASSISTANT_MESSAGES';
export const ADD_ASSISTANT_MESSAGE = 'ADD_ASSISTANT_MESSAGE';
export const SEND_STREAM_MESSAGE = 'SEND_STREAM_MESSAGE';
export const SET_ASSISTANT_SESSION_ID = 'SET_ASSISTANT_SESSION_ID';
export const SET_ASSISTANT_OPEN = 'SET_ASSISTANT_OPEN';
export const SET_ASSISTANT_UNREAD_MESSAGES = 'SET_ASSISTANT_UNREAD_MESSAGES';
export const SET_ASSISTANT_TYPING = 'SET_ASSISTANT_TYPING';
export const SET_ASSISTANT_LOADING = 'SET_ASSISTANT_LOADING';
export const SET_ASSISTANT_HAS_ERROR = 'SET_ASSISTANT_HAS_ERROR';
export const SET_ASSISTANT_CONNECTED = 'SET_ASSISTANT_CONNECTED';
export const ADD_TO_SIGNED_URL_CACHE = 'ADD_TO_SIGNED_URL_CACHE';
export const INCREMENT_ASSISTANT_UNREAD_MESSAGES = 'INCREMENT_ASSISTANT_UNREAD_MESSAGES';
export const TOGGLE_ASSISTANT_OPEN = 'TOGGLE_ASSISTANT_OPEN';

// Settings
export const SET_SETTINGS_MODAL_TYPE = 'SET_SETTINGS_MODAL_TYPE';
export const SET_SETTINGS_LOADING = 'SET_SETTINGS_LOADING';
export const SET_SETTINGS_ERROR = 'SET_SETTINGS_ERROR';
export const SET_IS_EMAIL_LOADING = 'SET_IS_EMAIL_LOADING';
export const SET_IS_PHONE_LOADING = 'SET_IS_PHONE_LOADING';
export const SET_IS_FIRSTNAME_LOADING = 'SET_IS_FIRSTNAME_LOADING';
export const SET_IS_LASTNAME_LOADING = 'SET_IS_LASTNAME_LOADING';
export const SET_NEXT_CHARGE_DATE = 'SET_NEXT_CHARGE_DATE';
export const SET_IS_CANCELING_SUBSCRIPTION = 'SET_IS_CANCELING_SUBSCRIPTION';

// Mobile redirect
export const SET_SENDING_LINK_TYPE = 'SET_SENDING_LINK_TYPE';

// Expense Review
export const SET_EXPENSES = 'SET_EXPENSES';
export const SET_IS_LOADING_EXPENSES = 'SET_IS_LOADING_EXPENSES';
export const SET_IS_INITIAL_EXPENSES_LOAD = 'SET_IS_INITIAL_EXPENSES_LOAD';
export const SET_EXPENSES_ERROR = 'SET_EXPENSES_ERROR';
export const SET_HAS_FETCHED_MAX_EXPENSES = 'SET_HAS_FETCHED_MAX_EXPENSES';
export const SET_EXPENSES_YEAR = 'SET_EXPENSES_YEAR';
export const SET_SAVINGS_AMOUNT = 'SET_SAVINGS_AMOUNT';
export const SET_CATEGORY_AMOUNTS = 'SET_CATEGORY_AMOUNTS';
export const SET_EXPENSE_REVIEW_ACCOUNTS = 'SET_EXPENSE_REVIEW_ACCOUNTS';
export const SET_CATEGORY_FILTER_SELECTIONS = 'SET_CATEGORY_FILTER_SELECTIONS';
export const SET_ACCOUNT_FILTER_SELECTIONS = 'SET_ACCOUNT_FILTER_SELECTIONS';
export const SET_STATUS_FILTER_SELECTIONS = 'SET_STATUS_FILTER_SELECTIONS';
export const SET_AMOUNT_FILTER_SELECTIONS = 'SET_AMOUNT_FILTER_SELECTIONS';
export const SET_MERCHANT_NAME_SEARCH = 'SET_MERCHANT_NAME_SEARCH';
export const SET_MERCHANT_SEARCH_RESULTS = 'SET_MERCHANT_SEARCH_RESULTS';
export const SET_MERCHANT_SEARCH_LOADING = 'SET_MERCHANT_SEARCH_LOADING';
export const SET_MERCHANT_NAME_FILTER_QUERY = 'SET_MERCHANT_NAME_FILTER_QUERY';
export const SET_SELECTED_EXPENSE = 'SET_SELECTED_EXPENSE';
export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES';
export const SET_EXPENSE_REVIEW_RULES = 'SET_EXPENSE_REVIEW_RULES';
export const SET_EXPENSE_REVIEW_CURRENT_RULE = 'SET_EXPENSE_REVIEW_CURRENT_RULE';
export const SET_IS_EXPENSE_REVIEW_MODAL_SUBMITTING = 'SET_IS_EXPENSE_REVIEW_MODAL_SUBMITTING';
export const SET_EXPENSE_REVIEW_MODAL_TYPE = 'SET_EXPENSE_REVIEW_MODAL_TYPE';
export const SET_MANUAL_EXPENSE_DATA = 'SET_MANUAL_EXPENSE_DATA';
export const SET_MANUAL_EXPENSE_AMOUNT = 'SET_MANUAL_EXPENSE_AMOUNT';
export const SET_SIDEBAR_COMPONENT = 'SET_SIDEBAR_COMPONENT';
export const SET_RETRO_INTERVAL = 'SET_RETRO_INTERVAL';
export const RESET_MERCHANT_NAME_SEARCH = 'RESET_MERCHANT_NAME_SEARCH';
export const SET_IS_EXPORTING = 'SET_IS_EXPORTING';
export const SET_AUTO_RULE = 'SET_AUTO_RULE';
export const SET_BULK_EDIT_EXPENSES = 'SET_BULK_EDIT_EXPENSES';
export const SET_BULK_EDIT_LOADING = 'SET_BULK_EDIT_LOADING';

// Holistic Onboarding
export const SET_HOLISTIC_ONBOARDING_LOADING = 'SET_HOLISTIC_ONBOARDING_LOADING';
export const SET_HOLISTIC_ONBOARDING_ERROR = 'SET_HOLISTIC_ONBOARDING_ERROR';
export const SET_HOLISTIC_ONBOARDING_UPDATING = 'SET_HOLISTIC_ONBOARDING_UPDATING';
export const SET_HOLISTIC_ONBOARDING_INITIAL_LOAD = 'SET_HOLISTIC_ONBOARDING_INITIAL_LOAD';
export const SET_HOLISTIC_ONBOARDING_PROGRESS = 'SET_HOLISTIC_ONBOARDING_PROGRESS';
export const SET_HOLISTIC_ONBOARDING_CONTENTFUL_VALUES = 'SET_HOLISTIC_ONBOARDING_CONTENTFUL_VALUES';
export const SET_HOLISTIC_ONBOARDING_JOB_CATEGORIES = 'SET_HOLISTIC_ONBOARDING_JOB_CATEGORIES';
export const SET_HOLISTIC_ONBOARDING_TAX_ESTIMATE = 'SET_HOLISTIC_ONBOARDING_TAX_ESTIMATE';
export const SET_HOLISTIC_ONBOARDING_GOALS = 'SET_HOLISTIC_ONBOARDING_GOALS';
export const SET_HOLISTIC_ONBOARDING_FIRST_NAME = 'SET_HOLISTIC_ONBOARDING_FIRST_NAME';
export const SET_HOLISTIC_ONBOARDING_LAST_NAME = 'SET_HOLISTIC_ONBOARDING_LAST_NAME';
export const SET_HOLISTIC_ONBOARDING_EMAIL = 'SET_HOLISTIC_ONBOARDING_EMAIL';
export const SET_HOLISTIC_ONBOARDING_PHONE = 'SET_HOLISTIC_ONBOARDING_PHONE';
export const SET_HOLISTIC_ONBOARDING_STATE = 'SET_HOLISTIC_ONBOARDING_STATE';
export const SET_HOLISTIC_ONBOARDING_MARRIED = 'SET_HOLISTIC_ONBOARDING_MARRIED';
export const SET_HOLISTIC_ONBOARDING_DEPENDENTS = 'SET_HOLISTIC_ONBOARDING_DEPENDENTS';
export const SET_HOLISTIC_ONBOARDING_RENT = 'SET_HOLISTIC_ONBOARDING_RENT';
export const SET_HOLISTIC_ONBOARDING_HOME_OWNER = 'SET_HOLISTIC_ONBOARDING_HOME_OWNER';
export const SET_HOLISTIC_ONBOARDING_STUDENT_LOANS = 'SET_HOLISTIC_ONBOARDING_STUDENT_LOANS';
export const SET_HOLISTIC_ONBOARDING_INCOME_TYPE = 'SET_HOLISTIC_ONBOARDING_INCOME_TYPE';
export const SET_HOLISTIC_ONBOARDING_W2_INCOME = 'SET_HOLISTIC_ONBOARDING_W2_INCOME';
export const SET_HOLISTIC_ONBOARDING_1099_INCOME = 'SET_HOLISTIC_ONBOARDING_1099_INCOME';
export const SET_HOLISTIC_ONBOARDING_PARTNER_W2_INCOME = 'SET_HOLISTIC_ONBOARDING_PARTNER_W2_INCOME';
export const SET_HOLISTIC_ONBOARDING_PARTNER_1099_INCOME = 'SET_HOLISTIC_ONBOARDING_PARTNER_1099_INCOME';
export const SET_HOLISTIC_ONBOARDING_JOB = 'SET_HOLISTIC_ONBOARDING_JOB';
export const SET_HOLISTIC_ONBOARDING_START_DATE = 'SET_HOLISTIC_ONBOARDING_START_DATE';
export const SET_HOLISTIC_ONBOARDING_DRIVE = 'SET_HOLISTIC_ONBOARDING_DRIVE';
export const SET_HOLISTIC_ONBOARDING_HOME = 'SET_HOLISTIC_ONBOARDING_HOME';
export const SET_HOLISTIC_ONBOARDING_MEAL = 'SET_HOLISTIC_ONBOARDING_MEAL';
export const SET_HOLISTIC_ONBOARDING_TRAVEL = 'SET_HOLISTIC_ONBOARDING_TRAVEL';
export const SET_HOLISTIC_ONBOARDING_DRAWER_OPEN = 'SET_HOLISTIC_ONBOARDING_DRAWER_OPEN';
export const SET_HOLISTIC_ONBOARDING_WEBVIEW_BANK_LINKED = 'SET_HOLISTIC_ONBOARDING_WEBVIEW_BANK_LINKED';
export const SET_HOLISTIC_ONBOARDING_ONBOARDING_EXPERIMENTS = 'SET_HOLISTIC_ONBOARDING_ONBOARDING_EXPERIMENTS';

// Referrals
export const SET_REFERRALS = 'SET_REFERRALS';
export const SET_PAYPAL_EMAIL = 'SET_PAYPAL_EMAIL';
export const SET_REFERRALS_CONTENT = 'SET_REFERRALS_CONTENT';
