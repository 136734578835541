import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import TaxFlowFeedbackModal from '@app/src/Components/TaxFlow/Common/TaxFlowFeedbackModal';
import TaxFlowRatingFeedbackStar from '@app/src/Components/TaxFlow/Question/TaxFlowRatingFeedbackStar';
import { setTaxFlowHoverRating, setFeedbackModalShow } from '@app/src/actions/taxFlowActions';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import { sendRatingNotification } from '@app/src/services/taxFlowService';
import { ratingSelector, hoverRatingSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowRatingFeedbackElement.scss';

const TaxFlowRatingFeedbackElement = ({ rating, hoverRating, setTaxFlowHoverRating, setFeedbackModalShow }) => {
  const [feedbackSavedCount, setFeedbackSavedCount] = useState(0);
  const [hasRequestedAppStoreRating, setHasRequestedAppStoreRating] = useState(false);

  const feedbackTextRef = useRef();

  const hasRating = rating > 0;
  const visibleRating = hoverRating || rating;
  const feedbackSavedWord = feedbackSavedCount < 2 ? 'recorded' : 'updated';
  const placeholderText = 'What could have been better?';
  const ratingMessage = {
    1: 'Terrible!',
    2: "Didn't like it",
    3: 'It was okay',
    4: 'Pretty good',
    5: 'It was great!'
  };

  const shouldShowFeedbackText = hasRating && (isReactNative() || rating < 5);

  useEffect(() => {
    // When rating or feedback submitted, scrolls to the bottom element
    if (shouldShowFeedbackText) {
      feedbackTextRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }

    if (rating === 5 && !hasRequestedAppStoreRating) {
      sentMsgToReactNative('tax_rating');
      setHasRequestedAppStoreRating(true);
    }
    if (rating === 5 && !isReactNative()) {
      trackActivity('tax filing: reviews modal opened');
      setFeedbackModalShow(true);
    }
  }, [rating, shouldShowFeedbackText, feedbackSavedCount, hasRating, hasRequestedAppStoreRating, setFeedbackModalShow]);

  const sendVerbatimFeedback = async (e) => {
    const text = e.target.value;
    if (text) {
      trackActivity('filing feedback: verbatim', { rating, text });
      await sendRatingNotification(text, rating);
      setFeedbackSavedCount(feedbackSavedCount + 1);
    }
  };

  return (
    <div className='taxflow-rating-feedback-element'>
      <h2>Rate your tax filing experience</h2>
      <div className='taxflow-rating-feedback-element-star-group' onMouseLeave={() => setTaxFlowHoverRating(0)}>
        {_.range(1, 6).map((val) => {
          return (
            <TaxFlowRatingFeedbackStar
              starValue={val}
              key={val}
              isFilled={visibleRating >= val}
              className='taxflow-rating-feedback-element-star'
            />
          );
        })}
      </div>
      {hasRating && <div className='taxflow-rating-feedback-element-star-message'>{ratingMessage[visibleRating]}</div>}
      {shouldShowFeedbackText && (
        <textarea
          ref={feedbackTextRef}
          className='form-control'
          rows='5'
          maxLength='1000'
          placeholder={placeholderText}
          id='feedback'
          onBlur={sendVerbatimFeedback}
        ></textarea>
      )}
      {feedbackSavedCount > 0 && (
        <p className='taxflow-rating-feedback-element-confirmation'>
          Your feedback was {feedbackSavedWord} - thank you!
        </p>
      )}
      <TaxFlowFeedbackModal />
    </div>
  );
};

const mapStateToProps = (state) => ({
  rating: ratingSelector(state),
  hoverRating: hoverRatingSelector(state)
});

const mapDispatchToProps = {
  setTaxFlowHoverRating,
  setFeedbackModalShow
};

const ConnectedTaxFlowRatingFeedbackElement = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowRatingFeedbackElement);

export default ConnectedTaxFlowRatingFeedbackElement;
