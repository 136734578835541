import React, { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import Loader from '@app/src/Components/Common/Loader/Loader';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import { deleteTaxData, useGetTaxAmountsQuery, useGetTaxDataQuery } from '@app/src/api/taxDataApi';
import VerifyIcon from '@app/src/assets/verify.svg?react';
import { trackActivity } from '@app/src/services/analyticsService';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { setIRSAccount } from '@app/src/services/taxFlowService';
import { taxAmountsSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { updateQuestionAnswer } from '@app/src/taxflow/main/services/mainService';
import { setIRSPaymentAccount } from '@app/src/taxflow/sections/submit/actions/submitActions';
import {
  COLLECTION_TYPE__SUBMIT_DEBIT,
  PATH_COMPONENT__SUBMIT_DEBIT_MANUAL,
  ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_ACCOUNT_ID
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { irsPaymentAccountSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import getChargeableAccounts from '@app/src/utils/pricing/getChargeableAccounts';
import '@app/src/Components/TaxFlow/Question/TaxFlowPaymentOptionsElement.scss';

const TaxFlowPaymentOptionsElement = ({
  setCurrentAnswer,
  setIRSPaymentAccount,
  setIRSAccount,
  accounts,
  irsPaymentAccount,
  history,
  currentAnswer,
  currentQuestion,
  ...props
}) => {
  const messageRef = useRef();
  const dispatch = useDispatch();

  const year = useSelector(yearSelector);
  const previousAnswer = usePrevious(currentAnswer);
  const previousIRSPaymentAccount = usePrevious(irsPaymentAccount);

  const { data: taxAmounts, isLoading: taxAmountsLoading } = useGetTaxAmountsQuery({ year });
  const { data: taxData, isLoading: taxDataLoading } = useGetTaxDataQuery({ year });

  const getMessage = useCallback(
    ({ answer }) => {
      const paymentPlanInfo = _.get(currentQuestion, ['question_meta', 'paymentPlanInfo']);

      if (_.get(answer, 'value') === 'paymentPlan' && paymentPlanInfo) {
        return paymentPlanInfo;
      }
    },
    [currentQuestion]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(_.get(history, ['location', 'search']));
    const account_id = searchParams.get('account_id');
    const fromPlaidLink = searchParams.get('plaid');
    const account = accounts.find((account) => account.account_id === account_id);

    if (account && irsPaymentAccount.account_id !== account.account_id) {
      setCurrentAnswer({ value: 'ach' });
      setIRSPaymentAccount(account);
      setIRSAccount();
      if (fromPlaidLink) {
        trackActivity('submit-debit: plaid linked', {
          institution_name: account.institution_name,
          institution_id: account.institution_id
        });
      }
      history.replace(history.location.pathname);
    }
  }, [accounts, currentAnswer, history, setCurrentAnswer, setIRSAccount, setIRSPaymentAccount, irsPaymentAccount]);

  useEffect(() => {
    const prevMessage = getMessage({
      answer: previousAnswer,
      irsPaymentAccount: previousIRSPaymentAccount
    });

    const currentMessage = getMessage({
      answer: currentAnswer,
      irsPaymentAccount: irsPaymentAccount
    });

    if (currentMessage && currentMessage !== prevMessage && messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentAnswer, previousAnswer, getMessage, irsPaymentAccount, previousIRSPaymentAccount]);

  useEffect(() => {
    const accountId = taxData.find(
      (item) =>
        item.coll_type === COLLECTION_TYPE__SUBMIT_DEBIT && item.slug === ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_ACCOUNT_ID
    )?.value;
    if (_.isEmpty(irsPaymentAccount) && accountId) {
      const maybeSelectedAccount = accounts.find((account) => account.account_id === accountId);
      !_.isNil(maybeSelectedAccount) && setIRSPaymentAccount(maybeSelectedAccount);
    }
  }, [accounts, taxData, irsPaymentAccount, setCurrentAnswer, setIRSPaymentAccount]);

  if (taxAmountsLoading || taxDataLoading) {
    return null;
  }

  const onClickAccount = async (account) => {
    if (currentAnswer.value === 'ach' && account.account_id === irsPaymentAccount.account_id) {
      setCurrentAnswer({ value: null });

      return;
    }

    setCurrentAnswer({ value: 'ach' });
    setIRSPaymentAccount(account);

    await setIRSAccount();
  };

  const onClickAddAccountEntry = async () => {
    // If user decides to add a new account, delete whatever data was already entered for submit-debit
    dispatch(deleteTaxData({ coll_type: COLLECTION_TYPE__SUBMIT_DEBIT, year }));
    history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_DEBIT_MANUAL}`);
  };

  const onClickPaymentPlanEntry = async () => {
    if (currentAnswer.value === 'paymentPlan') {
      setCurrentAnswer({ value: null });

      return;
    }

    setCurrentAnswer({ value: 'paymentPlan' });
  };

  const nameContainsMask = (account) => {
    if (!account || !account.name || !account.mask) return false;

    return account.name.includes(account.mask);
  };

  const { className, taxFlowLoading } = props;

  const accountHeader = _.get(currentQuestion, ['question_meta', 'accountHeader']);

  const message = getMessage({ answer: currentAnswer, irsPaymentAccount });
  const showPayLater = taxAmounts.federal_amount > 0 || _.some(taxAmounts.state_amounts, ({ amount }) => amount > 0);

  return (
    <>
      {taxFlowLoading && <Loader type='page' />}
      <h1 className='taxflow-payment-options-element-header'>{accountHeader}</h1>
      <div className={classNames('taxflow-payment-options-element-list', className)}>
        {accounts.map((account) => (
          <TaxFlowListItem
            key={account.account_id}
            handleChange={() => onClickAccount(account)}
            checked={currentAnswer.value === 'ach' && account.account_id === irsPaymentAccount.account_id}
          >
            {account.institution_name} - {account.name}
            {account.mask && !nameContainsMask(account) && ` (...${account.mask})`}
          </TaxFlowListItem>
        ))}
        <TaxFlowListItem handleChange={onClickAddAccountEntry}>Add a bank account</TaxFlowListItem>
        {showPayLater && (
          <TaxFlowListItem handleChange={onClickPaymentPlanEntry} checked={currentAnswer.value === 'paymentPlan'}>
            Pay Later
          </TaxFlowListItem>
        )}
        {message && (
          <div className='taxflow-payment-options-element-info' ref={messageRef}>
            <div className='steps-benefits-text-new-icon-container'>
              <VerifyIcon width={16} height={16} />
            </div>
            {message}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const accounts = getChargeableAccounts({ bankLinks: _.get(state, ['pricing2', 'bankLinks']) });

  return {
    accounts,
    currentAnswer: _.get(state, ['taxFlow', 'currentAnswer']),
    taxAmounts: taxAmountsSelector(state),
    irsPaymentAccount: irsPaymentAccountSelector(state),
    taxFlowLoading: _.get(state, ['taxFlow', 'loading'])
  };
};

const mapDispatchToProps = {
  setIRSPaymentAccount,
  setIRSAccount,
  setCurrentAnswer,
  updateQuestionAnswer
};

const ConnectedTaxFlowPaymentOptionsElement = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowPaymentOptionsElement);

export default ConnectedTaxFlowPaymentOptionsElement;
