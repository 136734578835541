import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateTaxProfileMutation } from '@app/src/api/taxProfileApi';
import { jobCategoriesSelector, topJobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { setUserWithObj } from '@app/src/services/analyticsService';

const useTaxProfileForm = ({ year, values, isLoading } = {}) => {
  const user = useSelector(userSelector);
  const allJobs = useSelector(jobCategoriesSelector);
  const topJobCategories = useSelector(topJobCategoriesSelector);
  const [updateTaxProfile] = useUpdateTaxProfileMutation();

  const onSubmit = useCallback(
    (profile) => {
      updateTaxProfile({
        year,
        profile
      });
    },
    [updateTaxProfile, year]
  );

  const handlePostSubmit = useCallback(
    ({ property, newValue, options = {} }) => {
      const { prepareForAnalytics } = options;

      const value = (() => {
        if (prepareForAnalytics) {
          return prepareForAnalytics(newValue);
        }

        return newValue;
      })();

      switch (property) {
        case 'jobs':
          {
            setUserWithObj(user?.phone, {
              jobs: value,
              jobsWithMetadata: null
            });
          }
          break;
        default:
          break;
      }
    },
    [user?.phone]
  );

  return {
    year,
    onSubmit,
    postSubmit: handlePostSubmit,
    allJobs,
    topJobCategories,
    isLoading,
    values
  };
};

export default useTaxProfileForm;
