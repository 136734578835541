import React from 'react';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { getCurrentYear } from '@app/src/global/Helpers';
import { colorNeutralGraphite } from '@app/src/theme';
import '@app/src/Components/Common/CompanyInfo/CompanyInfo.scss';

const CompanyInfo = () => (
  <Stack spacing={2} className='company-info'>
    <Stack className='contact-info' direction='row' spacing={2}>
      <div className='contact-info-section'>
        <div>44 Montgomery Street</div>
        <div>San Francisco, CA 94104</div>
      </div>
      <Divider orientation='vertical' sx={{ backgroundColor: colorNeutralGraphite, width: '2px', height: '30px' }} />
      <div className='contact-info-section'>
        <div>Tel: 415-644-8982</div>
      </div>
    </Stack>
    <div>
      <div>Copyright © {getCurrentYear()} Keeper Tax Inc. All rights reserved.</div>
    </div>
    <div className='privacy-info'>
      This website uses third party advertising cookies to serve you relevant ads. You may opt-out from these third
      party cookies by navigation to keepertax.com and clicking “Opt out”.
    </div>
    <Stack direction='row' justifyContent={'center'} spacing={2}>
      <Link
        href='https://www.keepertax.com/terms'
        target='_blank'
        rel='noopener noreferrer'
        style={{ color: colorNeutralGraphite }}
      >
        Terms of Service
      </Link>
      <Link
        href='https://www.keepertax.com/privacy'
        target='_blank'
        rel='noopener noreferrer'
        style={{ color: colorNeutralGraphite }}
      >
        Privacy Policy
      </Link>
      <Link
        href='mailto:support@keepertax.com'
        target='_blank'
        rel='noopener noreferrer'
        style={{ color: colorNeutralGraphite }}
      >
        Customer Support
      </Link>
    </Stack>
  </Stack>
);

export default CompanyInfo;
