import _ from 'lodash';
import { baseApi, TAGS } from '@app/src/api/baseApi';
import { getFulfilledRequestData } from '@app/src/api/utils';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const expensesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getExpenseReviewDetails: builder.query({
      query: ({ year }) => ({
        url: 'expense/get-expense-review-details',
        method: 'GET',
        params: {
          start: `${year}-01-01`,
          end: `${year}-12-31`
        }
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, { year }) => [{ type: TAGS.EXPENSE_REVIEW_DETAILS, id: year }]
    }),
    getAllExpenses: builder.query({
      query: ({ year }) => ({
        url: 'expense/search',
        method: 'GET',
        params: {
          start: `${year}-01-01`,
          end: `${year}-12-31`
        }
      }),
      transformResponse: (response) => response.data.expenses,
      providesTags: (result, error, { year }) => [{ type: TAGS.ALL_EXPENSES, id: year }]
    })
  })
});

export const { useGetExpenseReviewDetailsQuery, useGetAllExpensesQuery } = expensesApi;

export const getAllExpenses =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => expensesApi.endpoints.getAllExpenses.initiate({ year }),
      dispatch
    });

export const fetchExpenseReviewDetails =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => expensesApi.endpoints.getExpenseReviewDetails.initiate({ year }),
      dispatch
    });

export const allTaxYearExpensesSelector = (state) => {
  const year = yearSelector(state);
  const { data: expenses } = expensesApi.endpoints.getAllExpenses.select({ year })(state);
  return _.defaultTo(expenses, []);
};
