import _ from 'lodash';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { serverUrl } from '@app/src/global/Environment';
import history from '@app/src/keeperHistory';
import { logout } from '@app/src/services/authService';

const baseUrl = serverUrl();

export const TAGS = {
  // tax data
  ALL_EXPENSES: 'AllExpenses',
  BULK_UPLOAD_ALERT: 'BulkUploadAlert',
  CURRENT_QUESTIONNAIRE_QUESTION: 'CurrentQuestionnaireQuestion',
  ID_VERIFICATION_RESULT: 'IdVerificationResult',
  ID_VERIFICATION_QUESTIONS: 'IdVerificationQuestions',
  PAST_RETURNS: 'PastReturns',
  QUESTIONNAIRE_PROGRESS: 'QuestionnaireProgress',
  QUESTIONNAIRE_SUMMARY_PILLS: 'QuestionnaireSummaryPills',
  RETURN_STATUS: 'ReturnStatus',
  SSN_MATCHED: 'SsnMatched',
  SUBMIT_ISSUES: 'SubmitIssues',
  SUBMIT_TIMESTAMP: 'SubmitTimestamp',
  SUBMIT_WARNINGS: 'SubmitWarnings',
  TAX_AMOUNTS: 'TaxAmounts',
  TAX_INFO: 'TaxInfo',
  UI_STAGE: 'UIStage',

  // profile
  ACCOUNT_DETAILS: 'AccountDetails',
  CAMPAIGN: 'Campaign',
  FEATURES: 'Features',
  JOBS: 'Jobs',
  RETRO_STATUS: 'RetroStatus',
  TAX_ESTIMATE: 'TaxEstimate',
  TAX_PROFILE: 'TaxProfile',
  WORK_INFO: 'WorkInfo',
  APP_YEARS: 'AppYears',

  // plaid
  PAID: 'Paid',

  // expenses
  EXPENSE_REVIEW_DETAILS: 'ExpenseReviewDetails',

  // pricing
  SUBSCRIPTION_INFO: 'SubscriptionInfo'
};

export const FIXED_CACHE_KEYS = {
  GENERATE_QUESTIONNAIRE: 'generate-questionnaire'
};

const QUERY_RETRY_COUNT = 3;

const baseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}api/`,
  prepareHeaders: (headers) => {
    headers.set('Authorization', localStorage.getItem('KeeperToken'));
    // Headers to prevent return of cached data
    headers.set('Cache-Control', 'no-store');
    headers.set('Pragma', 'no-cache');
    headers.set('Expires', '0');
  },
  timeout: 10_000
});

const isUnauthorized = (response) => response?.data?.msg === 'Unauthorized Request';

export const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (isUnauthorized(result)) {
    await api.dispatch(logout());
    history.push('/');
  }

  return result;
};

const baseQueryWithRetry = retry(
  async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (isUnauthorized(result)) {
      await api.dispatch(logout());
      history.push('/');
      retry.fail(result.error, result.meta);
    }

    return result;
  },
  {
    maxRetries: QUERY_RETRY_COUNT
  }
);

export const baseApi = createApi({
  refetchOnReconnect: true,
  refetchOnFocus: true,
  // Keep unused query data for 3 mins (this is how long cached query state is valid after removal of all subscriptions)
  keepUnusedDataFor: 3 * 60,
  baseQuery: baseQueryWithRetry,
  tagTypes: _.values(TAGS),
  endpoints: () => ({})
});
