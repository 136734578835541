import React, { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import KeeperSlider from '@app/src/Components/Common/KeeperSlider/KeeperSlider';
import LinkedLockoutInput from '@app/src/Components/Common/LinkedLockoutInput/LinkedLockoutInput';
import ExpenseReviewCategoryDescription from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryDescription';
import ExpenseReviewCategoryDropdown from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryDropdown';
import ExpenseReviewEditPanelDropdown from '@app/src/Components/ExpenseReview/ExpenseReviewEditPanelDropdown';
import useGetOrCreateTaxProfileQuery from '@app/src/Components/TaxProfile/hooks/useGetOrCreateTaxProfileQuery';
import {
  setSelectedExpense,
  setExpenseReviewModalType,
  setSidebarComponent
} from '@app/src/actions/expenseReviewActions';
import { EXPENSE_REVIEW_STATUS_OPTIONS_LIST, OTHER_CATEGORY_ID } from '@app/src/constants/constants';
import { numberWith0DecimalPlaces, currencyWith2DecimalPlaces } from '@app/src/global/Helpers';
import useIsInConfirmAmounts from '@app/src/hooks/useIsInConfirmAmounts';
import {
  categoryIdsToDisplayNamesSelector,
  displayedDescriptionSelector,
  editModalPercentageLabelSelector,
  hideStatusEditModalSelector,
  isModalSubmittingSelector,
  loadingSelector,
  notDeductibleIdsSelector,
  recategorizeOptionsListSelector,
  selectedExpenseAllDataSelector,
  selectedExpenseSelector,
  allCategoriesSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { infoModalItemSelector } from '@app/src/selectors/taxFlowModalsSelectors';
import { isExpensesYearEqualToCurrentTaxYearSelector } from '@app/src/selectors/taxFlowSelectors';
import { workDetailsSelector } from '@app/src/selectors/workSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { deleteManualExpense } from '@app/src/services/expenseReviewService';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { determineExpenseSplitType } from '@app/src/utils/commonUtils';
import '@app/src/Components/ExpenseReview/ExpenseReviewModalEdit.scss';

const ExpenseReviewModalEdit = ({
  selectedExpense,
  className,
  categoryOptionsList,
  categoryIdsToDisplayNames,
  allCategories,
  selectedExpenseAllData,
  displayedDescription,
  notDeductibleIds,
  percentageLabel,
  hideStatus,
  setSelectedExpense,
  isModalSubmitting,
  setSelfUnlockModalOpen,
  deleteManualExpense,
  setExpenseReviewModalType,
  setSidebarComponent,
  loading
}) => {
  useEffect(() => {
    return () => {
      setSelectedExpense(null);
    };
  }, [setSelectedExpense]);
  const accountName = useMemo(() => _.get(selectedExpenseAllData, 'bank_acct_name'), [selectedExpenseAllData]);

  const { data: isInConfirmAmounts, isLoading: isInConfirmAmountsLoading } = useIsInConfirmAmounts();
  const isExpensesYearEqualToCurrentTaxYear = useSelector(isExpensesYearEqualToCurrentTaxYearSelector);

  const handleSliderChange = (value) => {
    if (isInConfirmAmounts && isExpensesYearEqualToCurrentTaxYear) {
      setSelfUnlockModalOpen(true);
      return;
    }
    if (!_.isNil(value)) {
      setSelectedExpense({
        transactionId: selectedExpense.transactionId,
        data: { ...selectedExpense.data, businessUsePercentage: value }
      });
    }
  };

  const handlePercentChange = (value) => {
    if (isInConfirmAmounts && isExpensesYearEqualToCurrentTaxYear) {
      setSelfUnlockModalOpen(true);
      return;
    }

    if (!_.isNil(value)) {
      setSelectedExpense({
        transactionId: selectedExpense.transactionId,
        data: { ...selectedExpense.data, businessUsePercentage: Number(value) }
      });
    }
  };

  const onStatusChange = useCallback(
    async ({ value }) => {
      if (isInConfirmAmounts && isExpensesYearEqualToCurrentTaxYear) {
        setSelfUnlockModalOpen(true);
        return;
      }
      if (value) {
        setSelectedExpense({
          transactionId: selectedExpense.transactionId,
          data: { ...selectedExpense.data, status: value }
        });
      }
    },
    [
      setSelectedExpense,
      selectedExpense,
      isInConfirmAmounts,
      setSelfUnlockModalOpen,
      isExpensesYearEqualToCurrentTaxYear
    ]
  );

  const onNotesChange = useCallback(
    async (value) => {
      if (isInConfirmAmounts && isExpensesYearEqualToCurrentTaxYear) {
        setSelfUnlockModalOpen(true);
        return;
      }
      setSelectedExpense({
        transactionId: selectedExpense.transactionId,
        data: { ...selectedExpense.data, note: value }
      });
    },
    [
      setSelectedExpense,
      selectedExpense,
      isInConfirmAmounts,
      setSelfUnlockModalOpen,
      isExpensesYearEqualToCurrentTaxYear
    ]
  );

  const onCategoryChange = useCallback(
    async ({ value, inputValue }) => {
      if (isInConfirmAmounts && isExpensesYearEqualToCurrentTaxYear) {
        setSelfUnlockModalOpen(true);
        return;
      }
      if (!_.isNil(value)) {
        setSelectedExpense({
          transactionId: selectedExpense.transactionId,
          data: {
            ...selectedExpense.data,
            keeper_category_id: value,
            status: _.includes(notDeductibleIds, value) ? 'no' : selectedExpense.data.status
          }
        });

        if (value === OTHER_CATEGORY_ID && inputValue) {
          trackActivity('recategorize to other', { query: inputValue });
        }
      }
    },
    [
      setSelectedExpense,
      selectedExpense,
      notDeductibleIds,
      isInConfirmAmounts,
      setSelfUnlockModalOpen,
      isExpensesYearEqualToCurrentTaxYear
    ]
  );

  const currentCategory = allCategories.find((category) => category.id === selectedExpenseAllData?.keeper_category_id);

  const lockoutCategoryInfo = determineExpenseSplitType(currentCategory);

  const getOrCreateTaxProfileQueryResult = useGetOrCreateTaxProfileQuery();

  const categoryUsagePercent = useMemo(() => {
    if (!getOrCreateTaxProfileQueryResult.data || !lockoutCategoryInfo) {
      return 0;
    }

    return getOrCreateTaxProfileQueryResult.data?.[lockoutCategoryInfo.percentageField] ?? 0;
  }, [getOrCreateTaxProfileQueryResult.data, lockoutCategoryInfo]);

  const handleNavigateToTaxProfile = useCallback(() => {
    setExpenseReviewModalType(null);
    setSidebarComponent('TaxProfileForm', { initialSection: 'CategoryUsage' });
  }, [setExpenseReviewModalType, setSidebarComponent]);

  const isDeductibleBusinessExpense =
    selectedExpenseAllData.status === 'yes' && currentCategory.transaction_type === 'possible_business_deduction';

  if (isInConfirmAmountsLoading) {
    return null;
  }

  return (
    <div className={classNames('expense-review-modal-edit', className)}>
      <div className='expense-review-modal-edit-merchant'>
        {selectedExpense?.data?.amount
          ? `${selectedExpenseAllData.clean_name} (${currencyWith2DecimalPlaces(selectedExpense?.data?.amount)})`
          : selectedExpenseAllData?.clean_name ?? ''}
      </div>
      <div className='expense-review-modal-edit-line-spacer' />
      {accountName !== 'manually added' && !_.includes(accountName, 'expense upload') ? (
        <div style={{ marginBottom: '16px' }}>
          {`Originally appeared as ${_.get(selectedExpenseAllData, 'name')} on your bank statement on ${moment(
            _.get(selectedExpenseAllData, 'date', ''),
            'YYYY-MM-DD'
          ).format('M/D/YYYY')}.`}
        </div>
      ) : (
        <div style={{ marginBottom: '16px' }}>
          {`Expense manually added. Purchased on ${moment(
            _.get(selectedExpenseAllData, 'date', ''),
            'YYYY-MM-DD'
          ).format('M/D/YYYY')}.`}
        </div>
      )}

      {displayedDescription && (
        <ExpenseReviewCategoryDescription description={displayedDescription} expense={selectedExpense?.data} />
      )}

      <Stack spacing={1}>
        {!hideStatus && (
          <div>
            <InputLabel>Status</InputLabel>
            <ExpenseReviewEditPanelDropdown
              labelId='edit-dropdown-status'
              disabled={isModalSubmitting}
              value={_.get(selectedExpenseAllData, 'status')}
              displayName={_.get(selectedExpenseAllData, 'statusDisplayName')}
              options={EXPENSE_REVIEW_STATUS_OPTIONS_LIST}
              onChange={({ target: { value } }) => onStatusChange({ value })}
            />
          </div>
        )}
        <div>
          <InputLabel id='edit-dropdown-category'>Category</InputLabel>
          <ExpenseReviewCategoryDropdown
            labelId='edit-dropdown-category'
            disabled={isModalSubmitting}
            className={'expense-review-modal-edit-category-dropdown'}
            value={_.get(selectedExpenseAllData, 'keeper_category_id')}
            displayName={
              categoryIdsToDisplayNames[_.get(selectedExpenseAllData, 'keeper_category_id')] || 'Select category'
            }
            options={categoryOptionsList}
            onChange={(value, inputValue) =>
              onCategoryChange({
                value: _.get(value, ['value']),
                inputValue
              })
            }
          />
        </div>

        {isDeductibleBusinessExpense && (
          <>
            {lockoutCategoryInfo ? (
              <>
                <LinkedLockoutInput
                  label='For personal use'
                  value={`${100 - categoryUsagePercent}%`}
                  lightExplainerText={`, default for ${lockoutCategoryInfo.label}`}
                  onClick={handleNavigateToTaxProfile}
                  analyticsType={lockoutCategoryInfo.label}
                />
                <LinkedLockoutInput
                  label={`For business / freelancing`}
                  value={`${categoryUsagePercent}%`}
                  lightExplainerText={`, default for ${lockoutCategoryInfo.label}`}
                  onClick={handleNavigateToTaxProfile}
                  analyticsType={lockoutCategoryInfo.label}
                />
              </>
            ) : (
              <KeeperSlider
                label={percentageLabel}
                showInput
                value={numberWith0DecimalPlaces(_.get(selectedExpenseAllData, ['businessUsePercentage']))}
                name='personal'
                onSliderChange={handleSliderChange}
                onInputChange={handlePercentChange}
                disabled={isModalSubmitting}
                format='###%'
              />
            )}
          </>
        )}

        <div>
          <InputLabel id='edit-input-note'>Note (optional)</InputLabel>
          <TextField
            id='edit-input-note'
            disabled={isModalSubmitting}
            value={_.get(selectedExpenseAllData, 'note')}
            onChange={(e) => onNotesChange(e.target.value)}
            placeholder='e.g. Meeting with my client Zach'
            fullWidth
          />
        </div>
        {(accountName === 'manually added' || _.includes(accountName, 'expense upload')) && (
          <Button
            fullWidth
            disabled={loading}
            onClick={deleteManualExpense}
            sx={{ color: 'danger.main' }}
            style={{ marginTop: '16px' }}
          >
            Delete
          </Button>
        )}
      </Stack>
    </div>
  );
};

const mapStateToProps = (state) => ({
  infoModalItem: infoModalItemSelector(state),
  selectedExpense: selectedExpenseSelector(state),
  categoryOptionsList: recategorizeOptionsListSelector(state),
  categoryIdsToDisplayNames: categoryIdsToDisplayNamesSelector(state),
  selectedExpenseAllData: selectedExpenseAllDataSelector(state),
  isModalSubmitting: isModalSubmittingSelector(state),
  hideStatus: hideStatusEditModalSelector(state),
  displayedDescription: displayedDescriptionSelector(state),
  notDeductibleIds: notDeductibleIdsSelector(state),
  percentageLabel: editModalPercentageLabelSelector(state),
  loading: loadingSelector(state),
  workDetails: workDetailsSelector(state),
  allCategories: allCategoriesSelector(state)
});

const mapDispatchToProps = {
  deleteManualExpense,
  setSelectedExpense,
  setSelfUnlockModalOpen,
  setExpenseReviewModalType,
  setSidebarComponent
};

const ConnectedExpenseReviewModalEdit = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalEdit);

export default ConnectedExpenseReviewModalEdit;
