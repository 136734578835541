import React, { useEffect } from 'react';
import _ from 'lodash';
import qs from 'qs';
import { connect, useSelector } from 'react-redux';
import useEvent from 'react-use-event-hook';
import LoginRedirect from '@app/src/Components/Login/LoginWithOTP/LoginRedirect';
import TaxFlowQuestion from '@app/src/Components/TaxFlow/Question/TaxFlowQuestion';
import { useGetCurrentQuestionnaireQuestionQuery, useGetUIStageQuery } from '@app/src/api/taxDataApi';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import { useIsQuestionnaireFlow } from '@app/src/hooks/useIsQuestionnaireFlow';
import history from '@app/src/keeperHistory';
import { formErrorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { isValidInputSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  getQuestionAnswer,
  updateQuestionAnswer,
  advanceToNextUrl,
  advanceToCurrentSectionPage
} from '@app/src/taxflow/main/services/mainService';
import { getNextButtonLabel, isPrev, getSlugByLocation } from '@app/src/taxflow/main/utils/mainUtils';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { SLUG__SUBMIT_CONFIRM_ID } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  SLUG__PAST_RETURNS,
  SLUG__SWITCH_TO_DESKTOP,
  TAXFLOW_BASE_URL,
  UI_STAGE__CONFIRM_AMOUNTS,
  UI_STAGE_METADATA
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { scrollToTop } from '@app/src/utils';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const TaxFlowQuestionContent = ({
  isAuthenticated,
  slug,
  collectionId,
  advanceToCurrentSectionPage,
  isPrev,
  nextButtonLabel,
  location,
  updateQuestionAnswer,
  advanceToNextUrl,
  getQuestionAnswer
}) => {
  const year = useSelector(yearSelector);

  const { data: currentQuestionnaireQuestion, isLoading: isCurrentQuestionnaireQuestionLoading } =
    useGetCurrentQuestionnaireQuestionQuery({ year });

  const isQuestionnaireFlow = useIsQuestionnaireFlow();
  useRedirectToHomeOnLockedUIStages({ slug });

  const getQuestionConditional = useEvent(async () => {
    if (!isAuthenticated) {
      return;
    }

    if (isCurrentQuestionnaireQuestionLoading && isQuestionnaireFlow) {
      return;
    }
    if (!isAuthenticated) {
      return;
    }

    if (!slug) {
      await advanceToCurrentSectionPage({ history });
      return;
    }

    await getQuestionAnswer({
      slug,
      location,
      history,
      currentQuestionnaireQuestion
    });

    scrollToTop();
  });

  useEffect(() => {
    getQuestionConditional();
  }, [
    collectionId,
    slug,
    currentQuestionnaireQuestion?.coll_type,
    currentQuestionnaireQuestion?.coll_id,
    currentQuestionnaireQuestion?.slug,
    getQuestionConditional
  ]);

  const onNext = async () => {
    const { hasNewError } = await updateQuestionAnswer({
      handleErrors: true
    });

    if (hasNewError) {
      // Form invalid
      const errorElement = document.querySelector(
        '.form-group:has(.Mui-error), .form-group:has(.tax-validation-warning-blue)'
      );

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      await advanceToNextUrl({ history, location });
    }
  };

  if (!isAuthenticated) {
    return <LoginRedirect location={location} />;
  }

  if (!slug) {
    return null;
  }

  return <TaxFlowQuestion onNext={onNext} isPrev={isPrev} NextButtonLabel={nextButtonLabel} />;
};

const mapStateToProps = (state, props) => ({
  currentQuestion: _.get(state, ['taxFlow', 'currentQuestion']),
  isPrev: isPrev({
    slug: _.get(state, ['taxFlow', 'currentQuestion', 'slug'])
  }),
  nextButtonLabel: getNextButtonLabel({
    question: _.get(state, ['taxFlow', 'currentQuestion'])
  }),
  slug: getSlugByLocation(props.location),
  isAuthenticated: _.get(state, ['auth', 'isAuthenticated']),
  collectionId: _.get(qs.parse(props.location.search.slice(1), { ignoreQueryPrefix: true }), 'collectionId'),
  isValidInput: isValidInputSelector(state, props),
  formErrors: formErrorsSelector(state, props)
});

const mapDispatchToProps = {
  getQuestionAnswer,
  updateQuestionAnswer,
  advanceToNextUrl,
  advanceToCurrentSectionPage
};

const ConnectedTaxFlowQuestionContent = connect(mapStateToProps, mapDispatchToProps)(TaxFlowQuestionContent);

export default ConnectedTaxFlowQuestionContent;

/**
 * Redirect to home upon access of a tax question when in a ui-stage where editing of tax data is not permissible
 *
 * For example, a user could be in ops review - they could, theoretically, input path component /tax-filing/self-id
 * This would send them to the self-id page, which we shouldn't allow, as they shouldn't be editing data when in ops review
 */
const useRedirectToHomeOnLockedUIStages = ({ slug }) => {
  const year = useSelector(yearSelector);
  const { data: uiStage, isLoading: isUIStageLoading } = useGetUIStageQuery({ year });
  const isUserBeingImpersonated = REACT_APP_ENV === 'staging-prod-db';

  useEffect(() => {
    if (isUserBeingImpersonated) {
      return;
    }
    if (_.includes([SLUG__PAST_RETURNS, SLUG__SWITCH_TO_DESKTOP], slug) || _.isNil(slug)) {
      return;
    }
    if (
      isUIStageLoading ||
      _.isEmpty(uiStage) ||
      _.some(UI_STAGE_METADATA, { stage: uiStage, editEnabled: true }) ||
      uiStage === UI_STAGE__CONFIRM_AMOUNTS
    ) {
      return;
    }
    // Expected on the confirm id page (users get redirected from there to purgatory)
    if (slug !== SLUG__SUBMIT_CONFIRM_ID) {
      defaultCaptureMessage('accessing tax question when in read-only ui stage', { slug, uiStage }, 'warning');
    }
    history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
  }, [slug, uiStage, isUIStageLoading, isUserBeingImpersonated]);
};
