import React, { useRef } from 'react';
import _ from 'lodash';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { CloseCircle, SearchNormal1, TickCircle, MessageQuestion } from 'iconsax-react';
import Chip from '@app/src/Components/Common/Chip/Chip';
import IconWithFallback from '@app/src/Components/Common/IconWithFallback';
import SquarePill from '@app/src/Components/Common/SquarePill/SquarePill';
import { SQUARE_PILL_ACTION_ICON_TYPES } from '@app/src/constants/constants';
import { colorSuccess } from '@app/src/theme';
import '@app/src/Components/Common/SearchList/SearchList.scss';

/**
 * @typedef Item
 *
 * @property {string} id
 * @property {string} name
 * @property {string} iconUrl
 * @property {boolean} checked
 * @property {boolean} showInList
 * @property {boolean} removable
 * @property {boolean} ignoreIconFilter
 *
 */

/**
 * @typedef SearchListProps
 *
 * @property {Item[]} items
 * @property {(item: Item) => void} onAddItem
 * @property {(item: Item) => void} onRemoveItem
 * @property {Item[]} selectedItems
 * @property {boolean} [addQuotesToCustomSearch] Whether to add quotes around exact matches between query and item name
 */

/**
 * @type {React.FC<SearchListProps>}
 */
const SearchList = ({
  items,
  listHeaderText,
  query,
  onAddItem,
  onRemoveItem,
  selectedItems,
  onSearch,
  onFocus = _.identity,
  style,
  iconFilter,
  searchPlaceholderText = 'Search',
  fallbackIcon,
  chipStyle = 'pill', // 'pill' or 'square',
  iconSize = 24,
  iconStyling = {},
  addQuotesToCustomSearch = false,
  ...listProps
}) => {
  const sortedCheckedItems = _.chain(selectedItems).sortBy('pillOrder').value();
  const listRef = useRef();

  return (
    <div className='search-list' style={style}>
      <Collapse in={!_.isEmpty(sortedCheckedItems)} timeout={200} style={{ marginTop: 0 }} unmountOnExit>
        <div className='search-list-bar'>
          {sortedCheckedItems.map((item) => (
            <>
              {chipStyle === 'square' ? (
                <SquarePill
                  key={item.id}
                  text={item.name}
                  onClick={() => onRemoveItem(item)}
                  decorativeIcon={
                    <IconWithFallback
                      iconStyling={iconStyling}
                      iconUrl={item.iconUrl}
                      fallbackIcon={fallbackIcon}
                      altText={`${item.id}-icon`}
                      size={iconSize}
                    />
                  }
                  actionIconType={
                    item?.removable ? SQUARE_PILL_ACTION_ICON_TYPES.REMOVE : SQUARE_PILL_ACTION_ICON_TYPES.QUESTION
                  }
                  item={item}
                />
              ) : (
                <Chip
                  key={item.id}
                  className='search-list-bar-chip'
                  icon={
                    <IconWithFallback
                      iconStyling={iconStyling}
                      iconUrl={item.iconUrl}
                      fallbackIcon={fallbackIcon}
                      altText={`${item.id}-icon`}
                      filter={!item.ignoreIconFilter ? iconFilter : undefined}
                    />
                  }
                  item={item}
                  text={item.name}
                  hasCloseIcon
                  onCloseClick={() => onRemoveItem(item)}
                />
              )}
            </>
          ))}
        </div>
      </Collapse>
      <TextField
        variant='outlined'
        margin='none'
        fullWidth
        value={query}
        placeholder={searchPlaceholderText}
        onChange={(e) => onSearch(e.target.value)}
        onFocus={onFocus}
        role='searchbox'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchNormal1 />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {!_.isEmpty(query) && (
                <IconButton onClick={() => onSearch('')}>
                  <CloseCircle size={24} />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
        inputProps={{
          'data-testid': 'searchInput'
        }}
      />
      <List
        variant='scroll'
        className='search-list-list'
        style={{
          '--list-top': `${_.get(listRef, ['current', 'offsetTop'], 0)}px`
        }}
        ref={listRef}
        {...listProps}
      >
        {listHeaderText && <p className='search-list-list-header'>{listHeaderText}</p>}
        {_.map(items, (item) => {
          const { id, name, iconUrl, checked, showInList, removable = true } = item;

          if (!showInList) return null;

          return (
            <ListItemButton
              className='search-list-list-item'
              key={id ?? name}
              selected={checked}
              divider
              onClick={() => (checked ? onRemoveItem(item) : onAddItem(item))}
              // Don't highlight selected items, but do highlight focused items
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0)'
                },
                '&.Mui-selected:focus-visible': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)'
                },
                '&:focus-visible': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)'
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)'
                }
              }}
            >
              {iconUrl && (
                <ListItemIcon className='search-list-list-icon' sx={{ minWidth: '24px' }}>
                  <IconWithFallback
                    iconStyling={iconStyling}
                    iconUrl={iconUrl}
                    fallbackIcon={fallbackIcon}
                    altText={`${name}-icon`}
                    filter={!item.ignoreIconFilter ? iconFilter : undefined}
                    size={iconSize}
                  />
                </ListItemIcon>
              )}
              <ListItemText
                primary={addQuotesToCustomSearch && query === name ? `"${name}"` : name}
                primaryTypographyProps={{ sx: { fontWeight: checked ? 500 : 400 } }}
              />
              {checked && (removable ? <TickCircle color={colorSuccess} /> : <MessageQuestion />)}
            </ListItemButton>
          );
        })}
      </List>
    </div>
  );
};

export default SearchList;
