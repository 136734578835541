import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import TaxValidationSelect from '@app/src/Components/TaxValidation/TaxValidationSelect';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';

class TaxFlowFormDropdownItem extends Component {
  componentDidMount() {
    const { question, answer } = this.props;

    if (_.isNil(_.get(answer, 'value'))) {
      if (question.question_meta) {
        const item = question.question_meta.find((x) => x.default === 1);
        if (item) {
          this.props.onChange({ slug: question.slug, value: item.value });
        }
      }
    }
  }

  onChange = (value) => {
    this.props.onChange({ slug: this.props.question.slug, value, endpoint_attr: this.props.question.endpoint_attr });
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ endpoint_attr: this.props.question.endpoint_attr });
    }
  };

  render() {
    const { question, answer } = this.props;

    const question_meta = question.question_meta;

    return (
      <div>
        <div className='form-group'>
          <TaxValidationSelect
            question={question}
            answer={answer}
            className='browser-default'
            name={question.slug}
            id={question.slug}
            onChange={(e) => this.onChange(e.target.value)}
            onFocus={this.onFocus}
            value={answer && !_.isNil(answer.value) ? answer.value : ''}
          >
            {question_meta.map((item) => (
              <option value={item.value} key={item.value}>
                {item.text}
              </option>
            ))}
          </TaxValidationSelect>
          <TaxValidationWarnings question={question} answer={answer} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  taxFlow: state.taxFlow
});

const ConnectedTaxFlowFormDropdownItem = connect(mapStateToProps)(TaxFlowFormDropdownItem);

export default ConnectedTaxFlowFormDropdownItem;
