import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import BaseTaxProfileForm from '@app/src/Components/TaxProfile/BaseTaxProfileForm';
import { useGetTaxProfileData } from '@app/src/Components/TaxProfile/hooks/useTaxProfile';
import useTaxProfileForm from '@app/src/Components/TaxProfile/hooks/useTaxProfileForm';
import { expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';

const TaxProfileForm = (props) => {
  const year = useSelector(expensesYearSelector);

  const { data, isLoading } = useGetTaxProfileData();

  const formProps = useTaxProfileForm({
    year,
    values: data,
    isLoading
  });

  useEffect(() => {
    trackActivity('navigation: tax profile', {
      year
    });
  }, [year]);

  return <BaseTaxProfileForm {...formProps} {...props} />;
};

export default TaxProfileForm;
