import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { errorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { isResidencySplitValid } from '@app/src/services/taxFlow/isValidInput';
import {
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_RESIDENCY,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE,
  ENDPOINT_ATTRIBUTE__RESIDENCY_SECOND_STATE
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { getQueryResultsByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';

const MONTHS = _.range(1, 13).map((month) => ({
  value: month,
  text: moment(month, 'MM').format('MMMM')
}));

const TaxFlowFormCalendarItem = ({
  currentQuestion,
  currentAnswer,
  question: subQuestion,
  answer: subAnswer,
  queryResults,
  resultLoading,
  errors,
  setCurrentAnswer,
  replaceStrings,
  setErrors
}) => {
  /**
   * @desc handles month selection changes
   */
  const handleMonthSelection = ({ month }) => {
    const states = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__STATE_RETURN,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
    });
    let newAnswer = {};

    if (currentQuestion.slug === SLUG__STATE_RESIDENCY) {
      const currentMonths = _.get(currentAnswer, ['value', subQuestion.slug, 'months']);
      const newMonths = isChecked({ month }) ? currentMonths.filter((x) => x !== month) : [...currentMonths, month];
      const uniqueSortedMonths = _.sortBy(_.uniq(newMonths));

      if (subQuestion.slug === ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE) {
        newAnswer = {
          value: {
            ...currentAnswer.value,
            [ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE]: {
              value: '1',
              state: _.get(states, [0, 'answer', 'value']),
              months: uniqueSortedMonths
            },
            [ENDPOINT_ATTRIBUTE__RESIDENCY_SECOND_STATE]: {
              value: _.get(currentAnswer, ['value', ENDPOINT_ATTRIBUTE__RESIDENCY_SECOND_STATE, 'value']) || '0',
              state: _.get(states, [1, 'answer', 'value']),
              months: _.get(currentAnswer, ['value', ENDPOINT_ATTRIBUTE__RESIDENCY_SECOND_STATE, 'months']) || []
            }
          }
        };
      } else {
        newAnswer = {
          value: {
            ...currentAnswer.value,
            [ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE]: {
              value: _.get(currentAnswer, ['value', ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE, 'value']) || '0',
              state: _.get(states, [0, 'answer', 'value']),
              months: _.get(currentAnswer, ['value', ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE, 'months']) || []
            },
            [ENDPOINT_ATTRIBUTE__RESIDENCY_SECOND_STATE]: {
              value: '1',
              state: _.get(states, [1, 'answer', 'value']),
              months: uniqueSortedMonths
            }
          }
        };
      }
      setCurrentAnswer(newAnswer);
      const isInputValid = isResidencySplitValid(newAnswer);
      // If input valid and not valid prior, allow user to progress via "next" in place of "skip"
      if (isInputValid) {
        setErrors(
          _.filter(
            errors,
            ({ slug }) =>
              slug !== ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE && slug !== ENDPOINT_ATTRIBUTE__RESIDENCY_SECOND_STATE
          )
        );
      }
    }
  };

  const isChecked = ({ month }) => {
    if (currentQuestion.slug === SLUG__STATE_RESIDENCY) {
      const currentMonths = _.get(currentAnswer, ['value', subQuestion.slug, 'months']);
      return _.includes(currentMonths, month);
    }
  };

  if (resultLoading) return null;

  return (
    <div className='form-group'>
      <TaxValidationLabel question={subQuestion} title={replaceStrings(subQuestion.title)} />
      <TaxValidationWarnings question={subQuestion} answer={subAnswer} />
      <div className='calendar-item-wrap'>
        {MONTHS.map((item, month) => (
          <div className='checkbox-item-calendar' key={subQuestion.id + '_' + month}>
            <TaxFlowListItem
              item={item}
              handleChange={() => handleMonthSelection({ month })}
              checked={isChecked({ month })}
            >
              <span>{item.text}</span>
            </TaxFlowListItem>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentAnswer: state.taxFlow.currentAnswer,
  resultLoading: state.taxFlow.resultLoading,
  errors: errorsSelector
});

const mapDispatchToProps = {
  setErrors
};

const ConnectedTaxFlowFormCalendarItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormCalendarItem);

export default ConnectedTaxFlowFormCalendarItem;
