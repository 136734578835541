import _ from 'lodash';
import { createSelector } from 'reselect';
import { baseApi, TAGS } from '@app/src/api/baseApi';
import { getFulfilledRequestData } from '@app/src/api/utils';
import { INCOME_COLLECTION_TYPES } from '@app/src/taxflow/sections/income/incomeConstants';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

// Api
const taxDataApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTaxInfo: builder.query({
      query: ({ year }) => ({
        url: `taxes`,
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, { year }) => [{ type: TAGS.TAX_INFO, id: year }]
    }),
    updateTaxData: builder.mutation({
      query: ({ taxData, generateSharedCollectionId, year }) => ({
        url: `taxes/user-tax-data`,
        method: 'POST',
        body: { taxData, generateSharedCollectionId, year }
      }),
      invalidatesTags: (result, error, { taxData, year }) =>
        _.compact([
          _.some(taxData, { coll_type: INCOME_COLLECTION_TYPES.FREELANCE }) && TAGS.JOBS,
          { type: TAGS.TAX_INFO, id: year },
          { type: TAGS.SUBMIT_WARNINGS, id: year }
        ]),
      async onQueryStarted({ taxData: taxDataUpdates, year }, { dispatch }) {
        try {
          dispatch(
            taxDataApi.util.updateQueryData('getTaxInfo', { year }, ({ taxData, errors, ...otherData }) => {
              /** Stringify a tax data entry. Useful for deep comparisons between tax entries */
              const serializeTaxData = ({ coll_type, coll_id, slug, value }) =>
                `${coll_type}_${coll_id}_${slug}_${value}`;

              const keyedCurrentTaxData = _.keyBy(taxData, serializeTaxData);
              // Get tax data updates - entries unique on coll_type, coll_id, slug. Take updates over current data.
              const updatedTaxData = _.chain([...taxData, ...taxDataUpdates])
                .keyBy(({ coll_type, coll_id, slug }) => `${coll_type}_${coll_id}_${slug}`)
                .values()
                .value();
              // Get tax data that has changed in value. If keyedCurrentTaxData has the serialized updated entry, we know that the data has not changed
              const changedTaxData = _.filter(
                updatedTaxData,
                (data) => !_.has(keyedCurrentTaxData, serializeTaxData(data))
              );

              return {
                ...otherData,
                taxData: updatedTaxData,
                // Dismiss errors on updated tax data
                errors: _.filter(
                  errors,
                  ({ coll_type, coll_id, slug }) => !_.some(changedTaxData, { coll_type, coll_id, slug })
                )
              };
            })
          );
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    }),
    deleteTaxData: builder.mutation({
      query: ({ coll_type, coll_id, slug, year }) => ({
        url: `taxes/user-tax-data`,
        method: 'DELETE',
        body: { coll_type, coll_id, slug, year }
      }),
      invalidatesTags: (result, error, { coll_type, year }) => [
        { type: TAGS.TAX_INFO, id: year },
        { type: TAGS.SUBMIT_WARNINGS, id: year },
        ...(coll_type === INCOME_COLLECTION_TYPES.FREELANCE ? [TAGS.JOBS] : [])
      ],
      async onQueryStarted({ coll_type, coll_id, slug, year }, { dispatch }) {
        try {
          dispatch(
            taxDataApi.util.updateQueryData(
              'getTaxInfo',
              { year },
              ({
                taxData,
                bulkUploadPills,
                carPills,
                homePills,
                homeAddressPreselectOptions,
                reviewPills,
                ...otherData
              }) => {
                return {
                  ...otherData,
                  taxData: _.filter(taxData, _.negate(_.matches(_.omit({ coll_type, coll_id, slug }, _.isUndefined)))),
                  bulkUploadPills: _.isNil(slug)
                    ? _.filter(
                        bulkUploadPills,
                        _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id }))
                      )
                    : bulkUploadPills,
                  carPills: _.isNil(slug)
                    ? _.filter(carPills, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
                    : carPills,
                  homePills: _.isNil(slug)
                    ? _.filter(homePills, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
                    : homePills,
                  homeAddressPreselectOptions: _.isNil(slug)
                    ? _.filter(
                        homeAddressPreselectOptions,
                        _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id }))
                      )
                    : homeAddressPreselectOptions,
                  reviewPills: _.isNil(slug)
                    ? reviewPills.map((section) => {
                        return {
                          ...section,
                          pills: _.filter(
                            section.pills,
                            _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id }))
                          )
                        };
                      })
                    : reviewPills
                };
              }
            )
          );
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    }),
    getUIStage: builder.query({
      query: ({ year }) => ({
        url: 'taxes/ui-stage',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.uiStage,
      providesTags: (result, error, { year }) => [{ type: TAGS.UI_STAGE, id: year }]
    }),
    updateUIStage: builder.mutation({
      query: ({ uiStage, year }) => ({
        url: 'taxes/ui-stage',
        method: 'POST',
        body: { uiStage, year }
      }),
      invalidatesTags: (result, error, { year }) => [{ type: TAGS.UI_STAGE, id: year }]
    }),
    getBulkUploadSuggestionPills: builder.query({
      query: ({ year }) => ({
        url: 'taxes/bulk-upload-suggestion-pills',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.bulkUploadSuggestionPills,
      providesTags: (result, error, { year }) => [{ type: TAGS.TAX_INFO, id: year }]
    }),
    getBulkUploadAlert: builder.query({
      query: ({ year }) => ({
        url: 'taxes/bulk-upload-alert',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.bulkUploadAlert,
      providesTags: (result, error, { year }) => [{ type: TAGS.BULK_UPLOAD_ALERT, id: year }]
    }),
    dismissBulkUploadAlert: builder.mutation({
      query: ({ error, year }) => ({
        url: 'taxes/dismiss-bulk-upload-alert',
        method: 'POST',
        body: { error, year }
      }),
      invalidatesTags: (result, error, { year }) => [{ type: TAGS.BULK_UPLOAD_ALERT, id: year }],
      async onQueryStarted({ year }, { dispatch }) {
        try {
          dispatch(taxDataApi.util.updateQueryData('getBulkUploadAlert', { year }, () => null));
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    }),
    getCurrentQuestionnaireQuestion: builder.query({
      query: ({ year }) => ({
        url: 'questionnaire/get-current-question',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.question,
      providesTags: (result, error, { year }) => [{ type: TAGS.CURRENT_QUESTIONNAIRE_QUESTION, id: year }]
    }),
    getQuestionnaireProgress: builder.query({
      query: ({ year }) => ({
        url: 'questionnaire/get-progress',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, { year }) => [{ type: TAGS.QUESTIONNAIRE_PROGRESS, id: year }]
    }),
    getQuestionnaireSummaryPills: builder.query({
      query: ({ year }) => ({
        url: 'taxes/questionnaire-summary-pills',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.questionnaireSummaryPills,
      providesTags: (result, error, { year }) => [{ type: TAGS.QUESTIONNAIRE_SUMMARY_PILLS, id: year }]
    }),
    generateQuestionnaire: builder.mutation({
      query: ({ year }) => ({
        url: 'questionnaire/generate-questions',
        method: 'POST',
        body: { year }
      }),
      invalidatesTags: (result, error, { year }) => [
        { type: TAGS.CURRENT_QUESTIONNAIRE_QUESTION, id: year },
        { type: TAGS.QUESTIONNAIRE_SUMMARY_PILLS, id: year },
        { type: TAGS.QUESTIONNAIRE_PROGRESS, id: year }
      ]
    }),
    progressToNextQuestionnaireQuestion: builder.mutation({
      query: ({ maybeFollowUpQuestion, year }) => ({
        url: 'questionnaire/progress-to-next-question',
        method: 'POST',
        body: { maybeFollowUpQuestion, year }
      }),
      transformResponse: (response) => ({
        currentQuestion: _.get(response, ['data', 'currentQuestion']),
        questionnaireProgress: _.get(response, ['data', 'questionnaireProgress'])
      }),
      invalidatesTags: (result, error, { year }) => [{ type: TAGS.QUESTIONNAIRE_SUMMARY_PILLS, id: year }],
      async onQueryStarted({ year }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const updatedCurrentQuestionnaireQuestion = _.get(data, 'currentQuestion');
          const updatedQuestionnaireProgress = _.get(data, 'questionnaireProgress');
          dispatch(
            taxDataApi.util.updateQueryData(
              'getCurrentQuestionnaireQuestion',
              { year },
              () => updatedCurrentQuestionnaireQuestion
            )
          );
          dispatch(
            taxDataApi.util.updateQueryData('getQuestionnaireProgress', { year }, () => updatedQuestionnaireProgress)
          );
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    }),
    goBackToPreviousQuestionnaireQuestion: builder.mutation({
      query: ({ year }) => ({
        url: 'questionnaire/go-back-to-previous-question',
        method: 'POST',
        body: { year }
      }),
      transformResponse: (response) => ({
        currentQuestion: _.get(response, ['data', 'currentQuestion']),
        questionnaireExited: _.get(response, ['data', 'questionnaireExited'], true)
      }),
      invalidatesTags: (result, error, { year }) => [{ type: TAGS.QUESTIONNAIRE_PROGRESS, id: year }],
      async onQueryStarted({ year }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const updatedCurrentQuestionnaireQuestion = _.get(data, 'currentQuestion');
          dispatch(
            taxDataApi.util.updateQueryData(
              'getCurrentQuestionnaireQuestion',
              { year },
              () => updatedCurrentQuestionnaireQuestion
            )
          );
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    }),
    getSubmitWarnings: builder.query({
      query: ({ year }) => ({
        url: 'taxes/submit-warnings',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.submitWarnings,
      providesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_WARNINGS, id: year }]
    }),
    dismissSubmitWarning: builder.mutation({
      query: ({ slug, year }) => ({
        url: 'taxes/dismiss-submit-warning',
        method: 'POST',
        body: { slug, year }
      }),
      invalidatesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_WARNINGS, id: year }],
      async onQueryStarted({ slug, year }, { dispatch }) {
        try {
          dispatch(
            taxDataApi.util.updateQueryData('getSubmitWarnings', { year }, (submitWarnings) => {
              return _.filter(submitWarnings, ({ slug: warningSlug }) => warningSlug !== slug);
            })
          );
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    }),
    getSubmitIssues: builder.query({
      query: ({ year }) => ({
        url: 'taxes/submit-issues',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.submitIssues,
      providesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_ISSUES, id: year }]
    }),
    updateSubmitIssues: builder.mutation({
      query: ({ updatedIssues, year }) => ({
        url: 'taxes/update-submit-issues',
        method: 'POST',
        body: { updatedIssues, year }
      }),
      invalidatesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_ISSUES, id: year }],
      async onQueryStarted({ updatedIssues, year }, { dispatch }) {
        try {
          dispatch(
            taxDataApi.util.updateQueryData('getSubmitIssues', { year }, () => {
              return updatedIssues;
            })
          );
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    }),
    getSsnMatched: builder.query({
      query: () => ({
        url: 'taxes/get-submit-ssn-matched',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.response,
      providesTags: () => [TAGS.SSN_MATCHED]
    }),
    executeSsnMatchedCheck: builder.mutation({
      query: () => ({
        url: 'taxes/get-id-match',
        method: 'POST'
      }),
      transformResponse: (response) => response.data.response.result,
      invalidatesTags: () => [TAGS.SUBMIT_WARNINGS, TAGS.SSN_MATCHED, TAGS.ID_VERIFICATION_QUESTIONS, TAGS.UI_STAGE]
    }),
    getIdVerificationQuestions: builder.query({
      query: () => ({
        url: 'taxes/get-id-verification-questions',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.response,
      providesTags: () => [TAGS.ID_VERIFICATION_QUESTIONS]
    }),
    answerIdVerificationQuestions: builder.mutation({
      query: ({ questions, answers }) => ({
        url: 'taxes/confirm-id',
        method: 'POST',
        body: { questions, answers }
      }),
      transformResponse: (response) => response.data.response,
      invalidatesTags: () => [TAGS.ID_VERIFICATION_RESULT]
    }),
    getIdVerificationResult: builder.query({
      query: () => ({
        url: 'taxes/get-id-verification-result',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.response,
      providesTags: () => [TAGS.ID_VERIFICATION_RESULT]
    }),
    getTaxAmounts: builder.query({
      query: ({ year }) => ({
        url: 'taxes/get-tax-amounts',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.taxAmounts,
      providesTags: (result, error, { year }) => [{ type: TAGS.TAX_AMOUNTS, id: year }]
    }),
    getSubmitTimestamp: builder.query({
      query: ({ year }) => ({
        url: 'taxes/submit-timestamp',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.submitTimestamp,
      providesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_TIMESTAMP, id: year }]
    }),
    getReturnStatus: builder.query({
      query: ({ year }) => ({
        url: 'taxes/return-status',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, { year }) => [{ type: TAGS.RETURN_STATUS, id: year }]
    }),
    getPastReturns: builder.query({
      query: () => ({
        url: 'taxes/past-returns',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.pastReturns,
      providesTags: [TAGS.PAST_RETURNS]
    }),
    getJobs: builder.query({
      query: () => ({
        url: 'taxes/jobs',
        method: 'GET'
      }),
      transformResponse: (response) => response.data,
      providesTags: () => [TAGS.JOBS]
    }),
    createJob: builder.query({
      query: (payload) => ({
        url: 'taxes/job',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: () => [TAGS.JOBS]
    }),
    updateJob: builder.query({
      query: (payload) => ({
        url: 'taxes/job',
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: () => [TAGS.JOBS]
    })
  })
});

// Actions

export const getTaxData =
  ({ year }) =>
  async (dispatch) => {
    const { taxData } = await getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getTaxInfo.initiate({ year }),
      dispatch
    });
    return taxData;
  };

export const getTaxErrors =
  ({ year }) =>
  async (dispatch) => {
    const { errors } = await getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getTaxInfo.initiate({ year }),
      dispatch
    });
    return errors;
  };

export const updateTaxData =
  ({ taxData, generateSharedCollectionId, year }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.updateTaxData.initiate({ taxData, generateSharedCollectionId, year }));
  };

export const deleteTaxData =
  ({ coll_type, coll_id, slug, year }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.deleteTaxData.initiate({ coll_type, coll_id, slug, year }));
  };

export const getUIStage =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getUIStage.initiate({ year }),
      dispatch
    });

export const updateUIStage =
  ({ uiStage, year }) =>
  async (dispatch) =>
    dispatch(taxDataApi.endpoints.updateUIStage.initiate({ uiStage, year }));

export const getBulkUploadPills =
  ({ year }) =>
  async (dispatch) => {
    const { bulkUploadPills } = await getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getTaxInfo.initiate({ year }),
      dispatch
    });
    return bulkUploadPills;
  };

export const getSsnMatched = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getSsnMatched.initiate, dispatch });

export const answerIdVerificationQuestions =
  ({ questions, answers }) =>
  async (dispatch) =>
    await dispatch(taxDataApi.endpoints.answerIdVerificationQuestions.initiate({ questions, answers }));

export const getIdVerificationQuestions = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getIdVerificationQuestions.initiate, dispatch });

export const getIdVerificationResult = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getIdVerificationResult.initiate, dispatch });

export const getCarPills =
  ({ year }) =>
  async (dispatch) => {
    const { carPills } = await getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getTaxInfo.initiate({ year }),
      dispatch
    });
    return carPills;
  };

export const getHomePills =
  ({ year }) =>
  async (dispatch) => {
    const { homePills } = await getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getTaxInfo.initiate({ year }),
      dispatch
    });
    return homePills;
  };

export const getHomeAddressPreselectOptions =
  ({ year }) =>
  async (dispatch) => {
    const { homeAddressPreselectOptions } = await getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getTaxInfo.initiate({ year }),
      dispatch
    });
    return homeAddressPreselectOptions;
  };

export const getJobs = () => async (dispatch) =>
  getFulfilledRequestData({
    initiateFunction: taxDataApi.endpoints.getJobs.initiate,
    dispatch
  });

export const createJob = (payload) => async (dispatch) => {
  await dispatch(taxDataApi.endpoints.createJob.initiate(payload));
};

export const updateJob = (payload) => async (dispatch) => {
  await dispatch(taxDataApi.endpoints.updateJob.initiate(payload));
};

// Hooks
export const {
  useUpdateTaxDataMutation,
  useGetUIStageQuery,
  useLazyGetUIStageQuery,
  useUpdateUIStageMutation,
  useGetBulkUploadSuggestionPillsQuery,
  useGetBulkUploadAlertQuery,
  useDismissBulkUploadAlertMutation,
  useGetCurrentQuestionnaireQuestionQuery,
  useGetQuestionnaireSummaryPillsQuery,
  useGetQuestionnaireProgressQuery,
  useGenerateQuestionnaireMutation,
  useGoBackToPreviousQuestionnaireQuestionMutation,
  useGetSubmitWarningsQuery,
  useDismissSubmitWarningMutation,
  useGetSubmitIssuesQuery,
  useLazyGetSubmitIssuesQuery,
  useUpdateSubmitIssuesMutation,
  useLazyGetSsnMatchedQuery,
  useLazyGetIdVerificationQuestionsQuery,
  useLazyGetIdVerificationResultQuery,
  useExecuteSsnMatchedCheckMutation,
  useGetTaxAmountsQuery,
  useLazyGetTaxAmountsQuery,
  useGetSubmitTimestampQuery,
  useGetReturnStatusQuery,
  useGetPastReturnsQuery,
  useGetJobsQuery
} = taxDataApi;

export const useGetTaxDataQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'taxData'),
      ...other
    })
  });
};

export const useGetTaxErrorsQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'errors'),
      ...other
    })
  });
};

export const useGetBulkUploadPillsQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'bulkUploadPills'),
      ...other
    })
  });
};

export const useGetReviewPillsQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'reviewPills'),
      ...other
    })
  });
};

export const useGetCarPillsQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'carPills'),
      ...other
    })
  });
};

export const useGetHomePillsQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'homePills'),
      ...other
    })
  });
};

export const useGetIsReturnBlockedQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'isReturnBlocked'),
      ...other
    })
  });
};

export const useGetHomeAddressPreselectOptionsQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'homeAddressPreselectOptions'),
      ...other
    })
  });
};

export const useGetCollectionTypeItemsQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'collectionTypeItems'),
      ...other
    })
  });
};

export const useGetHasTaskAssigneeQuery = (query, options) => {
  return taxDataApi.useGetTaxInfoQuery(query, {
    ...options,
    selectFromResult: ({ data, ...other }) => ({
      data: _.get(data, 'hasTaskAssignee'),
      ...other
    })
  });
};

export const jobsSelector = createSelector([taxDataApi.endpoints.getJobs.select()], ({ data: jobs }) => jobs || []);

export default taxDataApi;
