import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { Briefcase } from 'iconsax-react';
import InformationBanner from '@app/src/Components/Common/InformationBanner/InformationBanner';
import SearchList from '@app/src/Components/Common/SearchList/SearchList';
import { trackActivity } from '@app/src/services/analyticsService';

const JobSelect = ({
  allJobs,
  value,
  onChange,
  selectedJobs,
  setSelectedJobs,
  origin,
  style = { margin: 'auto' },
  emptyUntilFocused = true,
  listProps,
  topJobCategories
}) => {
  const [inputFocused, setInputFocused] = useState(!emptyUntilFocused);

  const handleAddItem = useHandleAddItem({ selectedJobs, setSelectedJobs, value, onChange });

  const [handleRemoveItem, warnings] = useHandleRemoveItem({ selectedJobs, setSelectedJobs, value, onChange });

  const formatSelectedJobItem = useFormatJobItem({ allJobs, selectedJobs, getSlug: (job) => job.contentfulSlug });

  const formatContentfulJobItem = useFormatJobItem({ allJobs, selectedJobs });

  const selectedItems = useMemo(() => selectedJobs.map(formatSelectedJobItem), [selectedJobs, formatSelectedJobItem]);

  const { searchQuery, handleSearch, listItems } = useSearch({
    allJobs,
    selectedJobs,
    inputFocused,
    topJobCategories,
    formatJobItem: formatContentfulJobItem,
    origin
  });

  const listHeaderText = useHeaderText({ searchQuery, inputFocused });

  return (
    <>
      {warnings.map((warning, i) => (
        <InformationBanner
          className='warning-box'
          key={i}
          text={warning.text}
          buttonText={warning.buttonText}
          link={warning.link}
        />
      ))}
      <SearchList
        items={listItems}
        listHeaderText={listHeaderText}
        onAddItem={handleAddItem}
        onRemoveItem={handleRemoveItem}
        onFocus={() => setInputFocused(true)}
        selectedItems={selectedItems}
        onSearch={handleSearch}
        query={searchQuery}
        style={style}
        fallbackIcon={<Briefcase />}
        chipStyle='square'
        iconSize={16}
        iconStyling={{ color: 'rgba(0, 0, 0, 1)' }}
        {...listProps}
        addQuotesToCustomSearch
      />
    </>
  );
};

const createCustomJob = ({ jobName, selectedJobs }) => {
  const customJobName = _.upperFirst(jobName.toLowerCase());

  // If the custom job searched matches an existing selected job,
  // then when we show it in the list, we need to unify the
  // warnings property with the selected job
  const matchingJob = _.find(selectedJobs, {
    name: customJobName
  });

  return {
    id: customJobName,
    slug: null,
    name: customJobName,
    iconUrl: null,
    showInList: true,
    removable: _.isEmpty(matchingJob?.warnings),
    earner: 'ME',
    warnings: matchingJob?.warnings,
    checked: selectedJobs.some((selectedJob) => selectedJob.name === customJobName)
  };
};

const useSearchResults = ({ searchQuery, inputFocused, defaultItems, selectedJobs, fuse, formatJobItem, allJobs }) => {
  return useMemo(() => {
    const isSearchEmpty = searchQuery.trim().length <= 0;

    if (isSearchEmpty && !inputFocused) {
      return [];
    }

    if (isSearchEmpty) {
      return defaultItems;
    }

    const customJob = createCustomJob({
      jobName: searchQuery,
      selectedJobs
    });

    const results = fuse.search(searchQuery);
    const matchedItems = results.map(({ item }) => formatJobItem(item));

    // Check if the custom job name already matches an existing job
    // if so only display the default job
    const exactMatch = allJobs.some((job) => job.name.toLowerCase() === searchQuery.toLowerCase());

    if (exactMatch) {
      return matchedItems;
    }

    return [...matchedItems, customJob];
  }, [allJobs, defaultItems, formatJobItem, fuse, inputFocused, searchQuery, selectedJobs]);
};

const useSearch = ({ allJobs, selectedJobs, inputFocused, topJobCategories, formatJobItem, origin }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const defaultItems = useDefaultItems({
    selectedJobs,
    topJobCategories,
    formatJobItem
  });

  const fuse = useMemo(
    () =>
      new Fuse(
        allJobs.filter((job) => job.name !== 'Other'),
        { keys: ['name', 'synonymsForThisJobType'], threshold: 0.5 }
      ),
    [allJobs]
  );

  const searchResults = useSearchResults({
    searchQuery,
    inputFocused,
    defaultItems,
    selectedJobs,
    fuse,
    formatJobItem,
    allJobs
  });

  const handleJobSearch = useMemo(
    () =>
      _.debounce((query) => {
        trackActivity('search jobs', { search_text: query.toLowerCase(), origin });
      }, 300),
    [origin]
  );

  const handleSearch = useCallback(
    (query) => {
      setSearchQuery(query);

      if (query) handleJobSearch(query);
    },
    [handleJobSearch]
  );

  return { searchQuery, handleSearch, listItems: searchResults };
};

const useFormatJobItem = ({ selectedJobs, allJobs, getSlug = (job) => _.get(job, 'slug', null) }) => {
  const jobsBySlug = useMemo(() => _.keyBy(allJobs, 'slug'), [allJobs]);

  return useCallback(
    (job) => {
      const slug = getSlug(job);
      const name = _.get(job, 'name', slug);
      const contentfulJob = jobsBySlug?.[slug];

      const selectedJobIndex = selectedJobs.findIndex(
        (selectedJob) => selectedJob.slug === slug || selectedJob.name === name
      );

      const selectedJob = selectedJobs[selectedJobIndex];

      const id = _.get(selectedJob, 'id', name);
      const earner = _.get(selectedJob, 'earner', 'ME');
      const warnings = _.get(selectedJob, 'warnings', []);

      return {
        id,
        slug,
        name: contentfulJob?.name ?? name,
        iconUrl: contentfulJob?.icon_url ?? null,
        earner,
        warnings: warnings,
        showInList: true,
        checked: selectedJobIndex >= 0,
        pillOrder: selectedJobIndex,
        removable: _.isEmpty(warnings),
        item: selectedJob
      };
    },
    [getSlug, jobsBySlug, selectedJobs]
  );
};

const useDefaultItems = ({ selectedJobs, topJobCategories, formatJobItem }) => {
  // Top job catetegories are the jobs to show if the search query is empty for a nicer default experience
  // This is pulled from a hardcoded list of slugs, which then finds the corresponding jobs in the contentful object
  // But for an onboarded user, the top job categories may include selected jobs as well. Some of these
  // jobs may have reported income, and thus be non-removable. So we need to unify the selected jobs into this list.
  return useMemo(() => {
    return topJobCategories
      .map((topJobCategory) => {
        const selectedJob = selectedJobs.find((selectedJob) => selectedJob.slug === topJobCategory?.slug);

        return {
          ...topJobCategory,
          warnings: selectedJob?.warnings
        };
      })
      .map(formatJobItem);
  }, [formatJobItem, topJobCategories, selectedJobs]);
};

const contentfulJobToJob = (contentfulJob) => {
  return {
    contentfulSlug: contentfulJob.slug,
    name: contentfulJob.name
  };
};

const useHandleAddItem = ({ value, onChange, selectedJobs, setSelectedJobs }) => {
  return useCallback(
    (job) => {
      if (!job.slug) {
        trackActivity('jobs: select custom job', {
          customJob: job.name
        });
      }

      if (value && onChange) {
        onChange([...value, contentfulJobToJob(job)]);
        return;
      }

      // TODO: deperecate this logic (need to check for usage of this)
      if (
        job.earner === 'ME' &&
        !selectedJobs.some((selectedJob) => selectedJob.slug === job.slug && selectedJob.name === job.name)
      ) {
        setSelectedJobs([job, ...selectedJobs]);
      }
    },
    [onChange, selectedJobs, setSelectedJobs, value]
  );
};

const useHandleRemoveItem = ({ value, onChange, selectedJobs, setSelectedJobs }) => {
  const [warnings, setWarnings] = useState([]);

  const handleRemoveItem = (item) => {
    if (value && onChange) {
      if (!_.isEmpty(item.warnings)) {
        setWarnings(item.warnings);
        return;
      }

      onChange(value.filter((job) => job !== item.item));
      setWarnings([]);
      return;
    }

    // TODO: deperecate this logic (need to check for usage of this)
    if (item.earner !== 'ME') return;

    if (item.removable) {
      setSelectedJobs(
        selectedJobs.filter((selectedJob) => selectedJob.name !== item.name && selectedJob.earner === 'ME')
      );
      return;
    }

    const warning = {
      text: `You reported income for ${item.name} in your tax return. Jobs with reported income cannot be removed.`,
      buttonText: 'View tax return',
      link: '/tax-filing/home'
    };

    setWarnings([warning]);
  };

  return [handleRemoveItem, warnings];
};

const useHeaderText = ({ searchQuery, inputFocused }) => {
  return useMemo(() => {
    const hasSearchQuery = searchQuery.trim().length > 0;

    if (hasSearchQuery) {
      return null;
    }

    if (inputFocused) {
      return 'Frequently selected jobs:';
    }

    return `e.g. “healthcare professional”, or “consultant”`;
  }, [inputFocused, searchQuery]);
};

export default JobSelect;
