import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTimeoutFn } from 'react-use';
import { isReactNative } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import { isWebviewFocusedSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

/**
 * Reload page if isLoading for time greater than thresholdMs
 */
export const useReloadPageOnLongLoad = ({ isLoading, thresholdMs }) => {
  const location = useLocation();
  const webviewFocused = useSelector(isWebviewFocusedSelector);
  const reloadPage = () => {
    trackActivity('page load timeout', { path: location.pathname });
    window.location.reload();
  };
  const [, cancelReload, queueReload] = useTimeoutFn(reloadPage, thresholdMs);
  useEffect(() => {
    // Skip reload if in-app and webview is not focused (ie the user is not on the taxes tab)
    if (isLoading && (webviewFocused || !isReactNative())) {
      queueReload();
    } else {
      cancelReload();
    }
  }, [isLoading, webviewFocused, queueReload, cancelReload]);
};
