import React from 'react';
import url from 'url';
import _ from 'lodash';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import TaxValidationNonBlockingWarning from '@app/src/Components/TaxValidation/TaxValidationNonBlockingWarning';
import WarningIcon from '@app/src/assets/warning.svg?react';
import {
  CATEGORY_TYPE_TAXFLOW_FORM_DATE,
  CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD,
  CATEGORY_TYPE_OPTIONS
} from '@app/src/constants/constants';
import history from '@app/src/keeperHistory';
import { collectionUploadStatusSelector } from '@app/src/selectors/taxFlowSelectors';
import { filteredErrorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { getFormUploadErrorMessage } from '@app/src/taxflow/collection/utils/collectionUtils';
import { bulkUploadErrorAttemptsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { allQuestionsSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { SLUG__CREDIT_CHILD_CARE_WHO } from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { PATH_COMPONENT__DEPENDENT_DETAIL } from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { SLUG__STATE_RESIDENCY, SLUG__STATE_RETURN } from '@app/src/taxflow/sections/state/constants/stateConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentCollectionIdSelector, queryResultsSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getQuestionOcrError } from '@app/src/taxflow/shared/utils/sharedUtils';
import { showWarningMessages } from '@app/src/taxflow/shared/utils/warningUtils';
import '@app/src/Components/TaxValidation/TaxValidationWarnings.scss';

const TaxValidationWarnings = ({
  className,
  currentQuestion,
  currentAnswer,
  question,
  answer,
  queryResults,
  formUploadStatus,
  errors,
  collectionId,
  slug1,
  slug2,
  allQuestions,
  addDependent,
  overrideCollectionId,
  year,
  ...props
}) => {
  const collId = overrideCollectionId ? `${overrideCollectionId}` : collectionId;
  const collType = _.get(question, 'collectionType');

  const blueWarningMessage =
    _.get(props, 'blueWarningMessage', false) || currentQuestion.slug === SLUG__STATE_RESIDENCY;
  const hasOcrError = !!getQuestionOcrError({ errors, question, collectionId: collId });
  const hasUploadError = _.get(formUploadStatus, 'status') === 'failed';
  const hasWarnings = showWarningMessages({
    question: currentQuestion,
    subquestion: question,
    answer: currentAnswer,
    subanswer: answer,
    props: { queryResults, slug1, slug2, errors, allQuestions, addDependent, collectionId: collId },
    year
  });

  const currentErrors = _.chain(errors)
    .filter({
      slug: question.endpoint_attr,
      coll_id: collId,
      coll_type: collType
    })
    .orderBy('blocking', 'desc')
    .value();

  if (
    !(hasWarnings || hasOcrError || hasUploadError) &&
    !(currentErrors && question.question_type === CATEGORY_TYPE_OPTIONS)
  ) {
    return null;
  }

  const getWarningMessage = (error) => {
    if (hasOcrError) {
      return "We couldn't detect this field in your form.";
    } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD && hasUploadError) {
      return getFormUploadErrorMessage({
        errorMessage: formUploadStatus.errorMessage,
        year
      });
    } else if (currentQuestion.slug === SLUG__STATE_RETURN) {
      return 'Please select a different state.';
    } else if (addDependent && question.slug === SLUG__CREDIT_CHILD_CARE_WHO) {
      const dependentUrl = url.format({
        pathname: `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__DEPENDENT_DETAIL}`,
        query: {
          collectionId: '1'
        }
      });
      return (
        <>
          <div className='tax-validation-warning-link-text'>First add a dependent to complete this section.</div>
          <Button
            fullWidth
            variant='label'
            className='tax-flow-add-dependent-btn'
            onClick={() => history.push(dependentUrl)}
          >
            Add a dependent
          </Button>
        </>
      );
    } else if (!addDependent && question.slug === SLUG__CREDIT_CHILD_CARE_WHO && !hasWarnings) {
      return null;
    } else if (question.question_type === CATEGORY_TYPE_TAXFLOW_FORM_DATE) {
      const formattedAnswer = moment(_.get(answer, 'value'), 'DD/MMM/YYYY');
      if (!formattedAnswer.isValid()) {
        return 'Please enter a valid date.';
      } else {
        return _.get(question, ['question_meta', 'warningMessage']);
      }
    } else if (
      error &&
      question.question_type === CATEGORY_TYPE_OPTIONS &&
      blueWarningMessage &&
      !_.get(error, 'warningMessage')
    ) {
      return 'This selection is invalid';
    } else {
      return (
        _.get(error, 'warningMessage') ||
        _.get(question, ['question_meta', 'warningMessage']) ||
        _.get(question, ['question_meta_ext', 'warningMessage'])
      );
    }
  };

  return currentErrors.map((error, i) => {
    const isBlocking = _.get(error, 'blocking', true);
    const warningMessage = getWarningMessage(error);
    return isBlocking ? (
      <div
        className={classNames('tax-validation-warning', className, {
          'tax-validation-warning-blue': blueWarningMessage
        })}
        key={i}
      >
        {blueWarningMessage && (
          <div>
            <WarningIcon width={20} height={20} />
          </div>
        )}
        {warningMessage}
      </div>
    ) : (
      <TaxValidationNonBlockingWarning
        key={i}
        warningMessage={warningMessage}
        validationSlug={_.get(error, 'validationSlug')}
        coll_id={collId}
        coll_type={collType}
      />
    );
  });
};

const mapStateToProps = (state) => ({
  currentQuestion: state.taxFlow.currentQuestion,
  currentAnswer: state.taxFlow.currentAnswer,
  queryResults: queryResultsSelector(state),
  errors: filteredErrorsSelector(state),
  collectionId: currentCollectionIdSelector(state),
  formUploadStatus: collectionUploadStatusSelector(state),
  allQuestions: allQuestionsSelector(state),
  bulkUploadErrorAttempts: bulkUploadErrorAttemptsSelector(state),
  year: yearSelector(state)
});

const ConnectedTaxValidationWarnings = connect(mapStateToProps)(TaxValidationWarnings);

export default ConnectedTaxValidationWarnings;
