import React from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hasQuestionSpecialChars } from '@app/src/global/Helpers';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

class TaxValidationInput extends React.Component {
  /**
   * Find the character that was added to the string (if applicable).
   */
  findAddedCharacter = (newVal, oldVal) => {
    // If this is the first character, return it.
    if (!oldVal && newVal) {
      return newVal;
    }
    // If characters were removed, return an empty string.
    if (!newVal || !oldVal || newVal.length < oldVal.length) {
      return '';
    }

    return _.find(newVal, (char, i) => char !== oldVal[i]);
  };

  onChange = (e, question, answer) => {
    const addedChar = this.findAddedCharacter(e.target.value, answer.value);
    // If a character was added and it's a special character, don't process the change.
    if (addedChar && hasQuestionSpecialChars(addedChar, question)) {
      return;
    }
    this.props.onChange(e);
  };

  render() {
    const { className, hasError, question, answer } = this.props;

    return (
      <TextField
        fullWidth
        className={classNames(
          'tax-validation-input',
          {
            'tax-validation-input-error': hasError
          },
          'form-control',
          className
        )}
        inputProps={{ maxLength: _.get(question, ['question_meta', 'maxLength']), 'data-testid': question.slug }}
        error={hasError}
        onChange={(e) => this.onChange(e, question, answer)}
        autoComplete={question.slug}
        {..._.omit(this.props, ['dispatch', 'className', 'hasError', 'question', 'answer', 'onChange'])}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxValidationInput = connect(mapStateToProps)(TaxValidationInput);

export default ConnectedTaxValidationInput;
