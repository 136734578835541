import React, { Component } from 'react';
import TaxFlowMultiOptionElement from '@app/src/Components/TaxFlow/Question/TaxFlowMultiOptionElement';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { SLUG__CREDIT_CHILD_CARE_WHO } from '@app/src/taxflow/sections/credit/constants/creditConstants';

class TaxFlowFormMultiOptionItem extends Component {
  onChange = (value) => {
    this.props.onChange({ slug: this.props.question.slug, value, endpoint_attr: this.props.question.endpoint_attr });
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ endpoint_attr: this.props.question.endpoint_attr });
    }
  };

  render() {
    const { question, answer } = this.props;

    if (!question) return null;

    return (
      <div className='form-group'>
        <TaxValidationLabel
          question={question}
          answer={answer}
          className='margin-bottom-none'
          title={this.props.replaceStrings(question.title)}
        />
        <TaxValidationWarnings question={question} answer={answer} blueWarningMessage />
        <TaxFlowMultiOptionElement
          {...this.props}
          isChildQuestion
          currentQuestion={question}
          currentAnswer={answer}
          onChange={this.onChange}
          onFocus={this.onFocus}
        />
        {question.slug === SLUG__CREDIT_CHILD_CARE_WHO && (
          <TaxValidationWarnings question={question} answer={answer} addDependent ignoreCurrentError />
        )}
      </div>
    );
  }
}

export default TaxFlowFormMultiOptionItem;
