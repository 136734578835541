import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useGetExpenseReviewDetailsQuery } from '@app/src/api/expensesApi';
import { useGetTaxDataQuery } from '@app/src/api/taxDataApi';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { ENDPOINT_ATTRIBUTE__STATE_RETURN } from '@app/src/taxflow/sections/state/constants/stateConstants';
import { NO_INCOME_TAX_STATES } from '@app/src/taxflow/shared/constants/sharedConstants';

export const useSubstitutedText = ({ text }) => {
  const year = useSelector(yearSelector);
  const { data: taxData, isLoading: taxDataLoading } = useGetTaxDataQuery({
    year
  });
  const { data: expenseReviewDetails, isLoading: expenseReviewDetailsLoading } = useGetExpenseReviewDetailsQuery({
    year
  });

  if (taxDataLoading || expenseReviewDetailsLoading) {
    return { isLoading: true, substitutedText: null };
  }

  const states = _.chain(taxData).filter({ slug: ENDPOINT_ATTRIBUTE__STATE_RETURN }).map('value').value();
  const taxableStates = _.filter(states, (state) => !NO_INCOME_TAX_STATES.has(state));
  const taxEntities = ['the IRS', ...taxableStates];
  const irsAndState = _.isEmpty(taxableStates)
    ? 'the IRS'
    : `${_.dropRight(taxEntities).join(', ')} and ${_.last(taxEntities)}`;

  const substitutedText = _.chain(text)
    .replace('{irsAndState}', irsAndState)
    .replace('{taxFilingYear}', year)
    .replace(
      '{deductionsAmount}',
      `$${_.chain(expenseReviewDetails?.deductionsAmount).defaultTo(0).round().value().toLocaleString()}`
    )
    .value();
  return { isLoading: false, substitutedText };
};
