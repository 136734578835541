import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _, { capitalize } from 'lodash';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import moment from 'moment';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import ExpenseReviewCategoryCell from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryCell';
import ExpenseReviewExpensesNoResultsText from '@app/src/Components/ExpenseReview/ExpenseReviewExpensesNoResultsText';
import ExpenseReviewFilterSelect from '@app/src/Components/ExpenseReview/ExpenseReviewFilterSelect';
import ExpenseReviewStatusCell from '@app/src/Components/ExpenseReview/ExpenseReviewStatusCell';
import BulkEditToolbar from '@app/src/Components/ExpenseReview/Table/BulkEditToolbar/BulkEditToolbar';
import ExpenseTableHeader from '@app/src/Components/ExpenseReview/Table/ExpenseTable/ExpenseTableHeader';
import ExpenseTableRow from '@app/src/Components/ExpenseReview/Table/ExpenseTable/ExpenseTableRow';
import {
  setBulkEditExpenses,
  setExpenseReviewModalType,
  setSelectedExpense
} from '@app/src/actions/expenseReviewActions';
import { AMOUNT_FILTER_OPTIONS_LIST, STATUS_FILTER_OPTIONS_LIST } from '@app/src/constants/constants';
import { EXPENSE_REVIEW_MODAL_TYPES } from '@app/src/constants/dashboardConstants';
import { currencyWith2DecimalPlaces } from '@app/src/global/Helpers';
import history from '@app/src/keeperHistory';
import {
  accountFilterOptionsListSelector,
  accountFilterSelectionsSelector,
  amountFilterSelectionsSelector,
  bulkEditExpensesSelector,
  bulkEditToolbarVisibleSelector,
  categoryFilterOptionsListSelector,
  categoryFilterSelectionsSelector,
  categoryIdsToDisplayNamesSelector,
  expensesCountSelector,
  expensesYearSelector,
  hasFetchedMaxExpensesSelector,
  hasFiltersSelectedSelector,
  isAccountFilterSelectedSelector,
  isAmountFilterSelectedSelector,
  isCategoryFilterSelectedSelector,
  isInitialExpensesLoadSelector,
  isStatusFilterSelectedSelector,
  loadingSelector,
  orderedExpensesSelector,
  selectedExpenseSelector,
  statusFilterSelectionsSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { loadAllTransactions } from '@app/src/services/bulkEditService';
import {
  getExpenses,
  updateAccountFilter,
  updateAmountFilter,
  updateCategoryFilter,
  updateExpenseStatus,
  updateStatusFilter
} from '@app/src/services/expenseReviewService';
import { hasActiveSubscriptionSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { colorAccentLight, colorNeutralMetallica, colorPrimaryAlt2, colorSurface } from '@app/src/theme';
import '@app/src/Components/ExpenseReview/Table/ExpenseTable/ExpenseTable.scss';
const TableComponents = {
  Scroller: forwardRef((props, ref) => <TableContainer {...props} ref={ref} />),
  Table: forwardRef((props, ref) => (
    <Table
      {...props}
      ref={ref}
      size='small'
      style={{ borderCollapse: 'separate', paddingBottom: 50, minWidth: '768px', overflowX: 'scroll' }}
    />
  )),
  TableHead,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  EmptyPlaceholder: () => (
    <tbody>
      <tr>
        <td colSpan='100%'>
          <ExpenseReviewExpensesNoResultsText />
        </td>
      </tr>
    </tbody>
  )
};

const ExpensesTable = ({
  orderedExpenses,
  isInitialExpensesLoad,
  expensesCount,
  hasFetchedMaxExpenses,
  loading,
  categoryFilterSelections,
  accountFilterSelections,
  statusFilterSelections,
  amountFilterSelections,
  isCategoryFilterSelected,
  categoryIdsToDisplayNames,
  isAmountFilterSelected,
  isAccountFilterSelected,
  isStatusFilterSelected,
  accountFilterOptionsList,
  categoryFilterOptionsList,
  getExpenses,
  updateCategoryFilter,
  updateAccountFilter,
  updateStatusFilter,
  updateAmountFilter,
  setSelectedExpense,
  setExpenseReviewModalType,
  setBulkEditExpenses,
  bulkEditExpenses,
  loadAllTransactions,
  selectedExpense,
  bulkEditToolbarVisible
}) => {
  const tableContainerRef = useRef(null);
  const [highlightedExpenseIndex, setHighlightedExpenseIndex] = useState(null);

  const handleRowClick = useCallback(
    ({ transaction_id, clean_name, keeper_category_id, note, ...row }) => {
      trackActivity('navigation: expense quickview', {
        transaction_id,
        clean_name,
        keeper_category_id,
        note,
        origin: 'web dashboard'
      });

      setSelectedExpense({
        transactionId: transaction_id,
        data: { transaction_id, clean_name, keeper_category_id, note, ...row }
      });

      setExpenseReviewModalType(EXPENSE_REVIEW_MODAL_TYPES.EDIT);
      setHighlightedExpenseIndex(null);
    },
    [setSelectedExpense, setExpenseReviewModalType]
  );

  const handleBulkEditSelect = ({ transaction_id }) => {
    const isSelected = bulkEditExpenses.includes(transaction_id);

    if (isSelected) {
      const expenseIndex = bulkEditExpenses.indexOf(transaction_id);

      setBulkEditExpenses([...bulkEditExpenses.slice(0, expenseIndex), ...bulkEditExpenses.slice(expenseIndex + 1)]);
      trackActivity('expense review: deselect expense', { transaction_id });
    } else {
      setBulkEditExpenses([...bulkEditExpenses, transaction_id]);
      trackActivity('expense review: select expense', { transaction_id });
    }
  };

  const handleSelectAllClick = async () => {
    if (bulkEditExpenses.length === orderedExpenses.length) {
      trackActivity('expense review: deselect all expenses');
      setBulkEditExpenses([]);
    } else {
      trackActivity('expense review: select all expenses');
      await loadAllTransactions();
    }
  };

  const handleFilterChange = async (filter, value) => {
    const analyticsValue = filter === 'account' ? _.compact(_.flattenDeep(value).map((item) => item.value)) : value;

    trackActivity('expense review: filter change', { filter, value: analyticsValue });

    switch (filter) {
      case 'category':
        await updateCategoryFilter(value);
        break;
      case 'account':
        await updateAccountFilter(value);
        break;
      case 'status':
        await updateStatusFilter(value);
        break;
      case 'amount':
        await updateAmountFilter(value);
        break;
      default:
        break;
    }
  };

  const renderCellContent = (cell) => {
    if (isInitialExpensesLoad) {
      return (
        <Skeleton
          animation='wave'
          height='36px'
          sx={{ pl: 2, bgcolor: colorPrimaryAlt2, color: colorAccentLight }}
          variant='text'
          width='50%'
        />
      );
    } else if (_.isEmpty(orderedExpenses)) {
      return '-';
    }

    return cell;
  };

  const headerCells = [
    {
      id: 'bulk',
      label: renderCellContent(
        <Checkbox
          onClick={handleSelectAllClick}
          checked={bulkEditExpenses.length === orderedExpenses.length && orderedExpenses.length > 0}
          indeterminate={bulkEditExpenses.length > 0 && bulkEditExpenses.length < orderedExpenses.length}
        />
      ),
      style: {
        width: '5%'
      },
      padding: 'checkbox'
    },
    {
      id: 'date',
      label: 'Date',
      style: {
        width: '15%'
      }
    },
    {
      id: 'merchant',
      label: 'Merchant',
      style: {
        width: '20%'
      }
    },
    {
      id: 'account',
      label: (
        <ExpenseReviewFilterSelect
          value={accountFilterSelections}
          filterSelected={isAccountFilterSelected}
          onChange={({ target: { value } }) => handleFilterChange('account', value)}
          label='Account'
          filterOptions={accountFilterOptionsList}
          menuItemValueKey='accountIds'
          menuItemDisplayKey='displayName'
        />
      ),
      style: {
        width: '20%'
      }
    },
    {
      id: 'status',
      label: (
        <ExpenseReviewFilterSelect
          value={statusFilterSelections}
          filterSelected={isStatusFilterSelected}
          onChange={({ target: { value } }) => handleFilterChange('status', value)}
          label='Status'
          filterOptions={STATUS_FILTER_OPTIONS_LIST}
          menuItemValueKey='value'
          menuItemDisplayKey='status'
        />
      ),
      style: {
        width: '20%'
      }
    },
    {
      id: 'category',
      label: (
        <ExpenseReviewFilterSelect
          value={categoryFilterSelections}
          filterSelected={isCategoryFilterSelected}
          onChange={({ newValue }) => handleFilterChange('category', newValue)}
          label='Category'
          filterOptions={categoryFilterOptionsList}
          menuItemValueKey='value'
          menuItemDisplayKey='displayName'
        />
      ),
      style: {
        width: '15%'
      }
    },
    {
      id: 'amount',
      label: (
        <ExpenseReviewFilterSelect
          value={amountFilterSelections}
          filterSelected={isAmountFilterSelected}
          onChange={({ target: { value } }) => handleFilterChange('amount', value)}
          label='Amount'
          filterOptions={AMOUNT_FILTER_OPTIONS_LIST}
          menuItemValueKey='value'
          menuItemDisplayKey='amount'
        />
      )
    }
  ];

  const tableData = useMemo(
    () => (isInitialExpensesLoad ? Array(30).fill({}) : orderedExpenses),
    [isInitialExpensesLoad, orderedExpenses]
  );

  const { search } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    // Prevents race condition since year is handled elsewhere
    if (searchParams.has('year')) {
      return;
    }

    if (loading || isInitialExpensesLoad) {
      return;
    }

    if (!searchParams.has('transaction_id')) {
      return;
    }

    const transactionId = searchParams.get('transaction_id');
    const index = _.findIndex(tableData, { transaction_id: transactionId });

    if (index === -1) {
      return;
    }

    tableContainerRef.current.scrollIntoView({
      index,
      align: 'start',
      behavior: 'smooth'
    });

    searchParams.delete('transaction_id');

    history.replace({
      search: searchParams.toString()
    });

    setHighlightedExpenseIndex(index);
  }, [isInitialExpensesLoad, loading, search, tableData]);

  const loadMoreExpenses = async () => {
    if (!hasFetchedMaxExpenses && !loading && !isInitialExpensesLoad) {
      await getExpenses({ offset: expensesCount });
    }
  };

  const isSelected = (transactionId) =>
    bulkEditExpenses.includes(transactionId) || selectedExpense?.transactionId === transactionId;

  const cellStyle = {
    paddingRight: '16px'
  };

  return (
    <>
      <BulkEditToolbar
        selectedRows={bulkEditExpenses}
        setSelectedRows={setBulkEditExpenses}
        categoryOptions={categoryFilterOptionsList}
      />
      <TableVirtuoso
        ref={tableContainerRef}
        style={{
          backgroundColor: colorSurface,
          borderRadius: bulkEditToolbarVisible ? '0 0 8px 8px' : '8px',
          border: `1px solid ${colorNeutralMetallica}`,
          borderTop: bulkEditToolbarVisible ? 'none' : `1px solid ${colorNeutralMetallica}`,
          // Footer height
          marginBottom: '84px'
        }}
        data={tableData}
        endReached={loadMoreExpenses}
        components={{
          ...TableComponents,
          TableRow: forwardRef((props, ref) => (
            <ExpenseTableRow
              {...props}
              selected={isSelected(props?.item?.transaction_id) || highlightedExpenseIndex === props['data-index']}
              ref={ref}
            />
          ))
        }}
        fixedHeaderContent={() => <ExpenseTableHeader headerCells={headerCells} />}
        itemContent={(_, data) => (
          <>
            <TableCell padding='checkbox'>
              {renderCellContent(
                <Checkbox
                  onClick={() => handleBulkEditSelect(data)}
                  checked={bulkEditExpenses.includes(data.transaction_id)}
                />
              )}
            </TableCell>
            <TableCell onClick={() => handleRowClick(data)} style={cellStyle}>
              {renderCellContent(<div>{moment(data.date, 'YYYY-MM-DD').format('ddd, M/D/YY')}</div>)}
            </TableCell>
            <TableCell onClick={() => handleRowClick(data)} style={cellStyle}>
              {renderCellContent(<div>{data.clean_name}</div>)}
            </TableCell>
            <TableCell onClick={() => handleRowClick(data)} style={cellStyle}>
              {renderCellContent(
                <div>
                  {(data?.account_id && capitalize(data?.acct_name)) || capitalize(data?.bank_acct_name) || 'Other'}
                </div>
              )}
            </TableCell>
            <TableCell onClick={() => handleRowClick(data)} style={cellStyle}>
              {renderCellContent(<ExpenseReviewStatusCell data={data} />)}
            </TableCell>
            <TableCell onClick={() => handleRowClick(data)} style={cellStyle}>
              {renderCellContent(
                <ExpenseReviewCategoryCell
                  id={data.keeper_category_id}
                  className='expense-review-expenses-table-cell-text-cat'
                  isPersonal={data.isPersonal}
                  value={categoryIdsToDisplayNames[data.keeper_category_id]}
                />
              )}
            </TableCell>
            <TableCell onClick={() => handleRowClick(data)} style={cellStyle}>
              {renderCellContent(<div>{currencyWith2DecimalPlaces(data.amount)}</div>)}
            </TableCell>
          </>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  orderedExpenses: orderedExpensesSelector(state),
  isInitialExpensesLoad: isInitialExpensesLoadSelector(state),
  expensesCount: expensesCountSelector(state),
  hasFetchedMaxExpenses: hasFetchedMaxExpensesSelector(state),
  loading: loadingSelector(state),
  expensesYear: expensesYearSelector(state),
  categoryFilterOptionsList: categoryFilterOptionsListSelector(state),
  categoryFilterSelections: categoryFilterSelectionsSelector(state),
  accountFilterSelections: accountFilterSelectionsSelector(state),
  statusFilterSelections: statusFilterSelectionsSelector(state),
  isCategoryFilterSelected: isCategoryFilterSelectedSelector(state),
  isAccountFilterSelected: isAccountFilterSelectedSelector(state),
  isAmountFilterSelected: isAmountFilterSelectedSelector(state),
  isStatusFilterSelected: isStatusFilterSelectedSelector(state),
  amountFilterSelections: amountFilterSelectionsSelector(state),
  accountFilterOptionsList: accountFilterOptionsListSelector(state),
  hasFiltersSelected: hasFiltersSelectedSelector(state),
  hasActiveSubscription: hasActiveSubscriptionSelector(state),
  categoryIdsToDisplayNames: categoryIdsToDisplayNamesSelector(state),
  bulkEditExpenses: bulkEditExpensesSelector(state),
  bulkEditToolbarVisible: bulkEditToolbarVisibleSelector(state),
  selectedExpense: selectedExpenseSelector(state)
});

const mapDispatchToProps = {
  getExpenses,
  updateExpenseStatus,
  updateCategoryFilter,
  updateAccountFilter,
  updateStatusFilter,
  updateAmountFilter,
  setSelectedExpense,
  setBulkEditExpenses,
  setExpenseReviewModalType,
  loadAllTransactions
};

const ConnectedExpenseReviewExpensesTable = connect(mapStateToProps, mapDispatchToProps)(ExpensesTable);

export default ConnectedExpenseReviewExpensesTable;
